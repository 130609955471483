<div class="p-fluid p-formgrid p-grid">
  <div class="p-field p-col-12">
    <h5>
      <div class="p-text-bold">ID: {{ currentCliente.ID }}</div>
    </h5>
    <h5>
      <div class="p-text-bold">NOMBRE: {{ currentCliente.NOMBRE }}</div>
    </h5>
    <h5>
      <div class="p-text-bold">TELEFONO: {{ currentCliente.TELEFONO }}</div>
    </h5>
    <h5>
      <div class="p-text-bold" [ngClass]="saldoB ? 'colorTextGreen' : 'colorTextRed'">
        SALDO: {{ currentCliente.SALDO | currency: "Q" }}
      </div>
    </h5>
  </div>
  <div class="p-field p-col-12">
    <span class="p-buttonset">
      <button
        pButton
        type="button"
        label="Ver nuevo"
        icon="pi pi-eye"
        class="p-button-sm p-button-success"
        (click)="closeDialog(0)"
      ></button>
      <button
        pButton
        type="button"
        label="Agregar nuevo"
        icon="pi pi-plus"
        class="p-button-sm p-button-primary p-ml-2"
        (click)="closeDialog(1)"
      ></button>
      <button
        pButton
        type="button"
        label="Regresar"
        icon="pi pi-times"
        class="p-button-sm p-button-danger p-ml-2"
        (click)="closeDialog(2)"
      ></button>
    </span>
  </div>
</div>
