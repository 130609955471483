import { Cliente } from './cliente';

export class Transaccion {
  ID: number = 0; //IDENTIFICACION UNICA PARA LA TRANSACCION
  FECHA: Date = new Date(); //FECHA QUE SE REALIZO LA TRANSACCION
  DE: number = 0; //CLIENTE QUE ENVIA LA TRANSACCION
  PARA: number = 0; //CLIENTE QUE RECIBE LA TRANSACCION

  //GASTO
  DESCRIPCION: string = '';

  //TRANSACCIONES EXTRANJERAS
  MONEDA: string = ''; //MONEDA
  CMONEDA: number = 0; //CANTIDAD DE MONEDA

  FCOM: number = 0; //FACTOR DE COMPRA
  FCOMT: boolean = false; //CONVERSION POR DIVISION O MULTIPLICACION PARA LA COMPRA
  QCOM: number = 0; //COMPRA EN QUETZALES

  FVEN: number = 0; //FACTOR DE VENTA
  FVENT: boolean = false; //CONVERSION POR DIVISION O MULTIPLICACION PARA LA VENTA
  QVEN: number = 0; //VENTA EN QUETZALES

  GAN: number = 0; //GANANCIA O PERDIDA EN QUETZALES

  //TRANSFERENCIA DE MONEDA LOCAL
  QTRAN: number = 0;

  //CONSTANCIA DE LAS BOLETAS
  BOLETAS: string[] = [];
  BANCO: string = '';
  DESTINATARIO: string = '';
  COMPROBANTE: string = '';
  OBSERVACIONES: string = '';

  IMAGEINDEX: number = 0;

  //TIPO
  TIPO: number = 0; //1 LOCAL, 0 EXTRANJERA, 2 GASTO

  //CAMPOS NO OBLIGATORIOS SOLO UTILIZADOS EN APP
  NCLIENTETRANSDE: Cliente = new Cliente();
  NCLIENTETRANSPARA: Cliente = new Cliente();
}
