import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Cliente } from 'src/app/models/cliente';
import { Transaccion } from 'src/app/models/transaccion';
import { ControllerServicioService } from 'src/app/servicios/controller-servicio.service';

@Component({
  selector: 'app-transacciones',
  templateUrl: './transacciones.component.html',
  styleUrls: ['./transacciones.component.scss'],
})
export class TransaccionesComponent implements OnInit {
  transacciones: Transaccion[] = [];
  blocked: boolean = true;

  startDate: Date = new Date();
  endDate: Date = new Date();
  optionFilter: string = 'Todos';

  optionsFilter: string[] = [];

  constructor(
    private Ruta: Router,
    public ctrlService: ControllerServicioService
  ) {
    this.optionsFilter = ['Todos', 'Local', 'Extranjero', 'Gasto'];
    this.startDate.setDate(this.startDate.getDate() - 1);
    this.cargarTransacciones();
  }

  ngOnInit(): void {}

  initTransaccion() {
    this.Ruta.navigateByUrl('/addTransaccion');
  }

  cargarTransacciones() {
    this.blocked = true;
    this.ctrlService
      .getTransacciones(
        this.convertDateSQL(this.startDate),
        this.convertDateSQL(this.endDate),
        this.optionFilter
      )
      .subscribe(
        (res) => {
          this.transacciones = res;
          this.blocked = false;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  selectTransaccion(trans: Transaccion) {
    this.ctrlService.setTransaccionSelected(trans);
    this.Ruta.navigateByUrl('/Transaccion');
  }

  convertDateSQL(date: Date): string {
    return moment(date).format('yyyy-MM-DD');
  }

  reiniciar() {
    this.startDate = new Date();
    this.endDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 1);
    this.optionFilter = 'Todos';
    this.cargarTransacciones();
  }
}
