import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import { ControllerServicioService } from './servicios/controller-servicio.service';
declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService],
})
export class AppComponent implements OnInit {
  title = 'transac';
  items: MenuItem[] = [];
  logued: boolean = false;
  pass: string = '';
  user: string = '';

  rutaPath:string ='https://citransapp2023.ml/TranServer/backup.php'
  //rutaPath:string = 'https://transacapp.000webhostapp.com/TranServer/backup.php';
  //rutaPath:string = 'http://transacest.42web.io/TranServer/backup.php';

  //CINTO
  //rutaPath:string = 'https://transaccc1002.000webhostapp.com/TranServer/backup.php';

  constructor(
    private config: PrimeNGConfig,
    private Ruta: Router,
    public ctrlService: ControllerServicioService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.config.setTranslation({
      startsWith: 'Inicie con',
      contains: 'Contenga',
      notContains: 'No contenga',
      endsWith: 'Termine con',
      equals: 'Igual a',
      notEquals: 'No igual que',
      noFilter: 'Sin filtros',
      lt: 'Menor que',
      lte: 'Menor o igual que',
      gt: 'Mayor que',
      gte: 'Mayor o igual que',
      is: 'Es',
      isNot: 'No es',
      before: 'Antes',
      after: 'Despues',
      clear: 'Limpiar',
      apply: 'Aplicar',
      matchAll: 'Todos',
      matchAny: 'Cualquiera',
      addRule: 'Add Rule',
      removeRule: 'Quitar regla',
      accept: 'Si',
      reject: 'No',
      choose: 'Elegir',
      upload: 'Subir',
      cancel: 'Cancelar',
      dayNames: [
        'Domingo',
        'Lunes',
        'Martes',
        'Miercoles',
        'Jueves',
        'Viernes',
        'Sabado',
      ],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
      monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Deciembre',
      ],
      monthNamesShort: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
      ],
      today: 'Hoy',
      weekHeader: 'Wk',
      weak: 'Debil',
      medium: 'Medio',
      strong: 'Fuerte',
      passwordPrompt: 'Ingrese la contraseña',
      dateAfter: 'Despues de',
      dateBefore: 'Antes de',
      dateIs: 'Igual a',
      dateIsNot: 'Diferente de',
    });
    this.items = [
      {
        label: 'Clientes',
        icon: 'pi pi-fw pi-users',
        command: () => {
          this.Ruta.navigateByUrl('/Clientes');
        },
      },
      {
        label: 'Transacciones',
        icon: 'pi pi-fw pi-ticket',
        command: () => {
          this.Ruta.navigateByUrl('/Transacciones');
        },
      },
      {
        label: 'Monedas',
        icon: 'pi pi-fw pi-money-bill',
        command: () => {
          this.Ruta.navigateByUrl('/monedaManager');
        },
      },
      {
        label: 'Diario',
        icon: 'pi pi-fw pi-chart-bar',
        command: () => {
          this.Ruta.navigateByUrl('/diario');
        },
      },
      {
        label: 'Backup',
        icon: 'pi pi-fw pi-cloud-download',
        command: () => {
          this.descargarBackup();
        },
      },
    ];
    this.logued = Boolean(localStorage.getItem('lacosteLogin')) || false;
  }

  checkLogin() {
    this.ctrlService.checkLogin(this.user, this.pass).subscribe((res) => {
      if (res) {
        this.logued = res;
        localStorage.setItem('lacosteLogin', JSON.stringify(res));
        this.Ruta.navigateByUrl('');
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Mensaje del sistema',
          detail: 'Login incorrecto',
          data: { idtrans: true },
        });
      }
    });
  }

  descargarBackup(){
    const file = this.rutaPath;
    FileSaver.saveAs(file,'backup'+(new Date()).getTime()+'.sql');
  }

}
