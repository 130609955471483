<p-blockUI [blocked]="blocked" class="fixMask">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
<p-card>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-col-12">
      <div class="p-field p-col-12 p-md-2">
        <button
          pButton
          type="button"
          label="Iniciar transferencia"
          icon="pi pi-plus"
          iconPos="right"
          class="p-button-success p-button-sm"
          (click)="initTransaccion()"
        ></button>
      </div>
      <p-divider></p-divider>
      <div class="p-col-12 p-grid">
        <div class="p-col-12">
          <h2>Transferencias</h2>
        </div>
        <div class="p-col-12">
          <p-divider></p-divider>
        </div>
        <div class="p-col-12 mt-1N">
          <h3>Filtrar</h3>
        </div>
        <div class="p-col-12 p-grid">
          <div class="p-field p-col-12 p-lg-2">
            <label for="startDate">Fecha inicial</label>
            <p-calendar
              [(ngModel)]="startDate"
              dateFormat="yy-mm-dd"
              inputId="startDate"
            ></p-calendar>
          </div>
          <div class="p-field p-col-12 p-lg-2">
            <label for="endDate">Fecha final</label>
            <p-calendar
              [(ngModel)]="endDate"
              dateFormat="yy-mm-dd"
              inputId="endDate"
            ></p-calendar>
          </div>
          <div class="p-field p-col-12 p-lg-2">
            <label for="filterOption">Estado</label>
            <p-dropdown
              id="filterOption"
              [options]="optionsFilter"
              [(ngModel)]="optionFilter"
            ></p-dropdown>
          </div>
          <div class="p-mt-4 p-md-1 p-lg-1">
            <button
              pButton
              pRipple
              type="button"
              icon="pi pi-filter"
              class="p-button-primary p-button-sm"
              (click)="cargarTransacciones()"
            ></button>
            <button
              pButton
              pRipple
              type="button"
              icon="pi pi-refresh"
              class="p-button-success p-button-sm p-ml-2"
              (click)="reiniciar()"
            ></button>
          </div>
        </div>
        <div class="p-col-12">
          <p-divider></p-divider>
        </div>
        <div class="p-col-12">
          <p-table
            [value]="transacciones"
            styleClass="p-datatable-sm p-datatable-responsive-demo"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>ID</th>
                <th pSortableColumn="FECHA">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    Fecha
                    <p-columnFilter
                      type="date"
                      field="FECHA"
                      display="menu"
                    ></p-columnFilter>
                  </div>
                </th>
                <th>Tipo</th>
                <th pSortableColumn="DE">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    De<p-columnFilter
                      type="text"
                      field="NCLIENTETRANSDE.NOMBRE"
                      display="menu"
                    ></p-columnFilter>
                  </div>
                </th>
                <th pSortableColumn="PARA">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    Para<p-columnFilter
                      type="text"
                      field="NCLIENTETRANSPARA.NOMBRE"
                      display="menu"
                    ></p-columnFilter>
                  </div>
                </th>
                <th pSortableColumn="CANTIDAD">Cantidad</th>
                <th pSortableColumn="GAN">Ganancia</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-cli>
              <tr class="manita" (click)="selectTransaccion(cli)">
                <td class="blackText">{{ cli.ID }}</td>
                <td>{{ cli.FECHA | date: "MM/dd/yy" }}</td>
                <td>
                  <span class="shw">Tipo: </span
                  >{{
                    cli.TIPO == 1
                      ? "LOCAL"
                      : cli.TIPO == 0
                      ? "EXTRANJERA"
                      : "GASTO"
                  }}
                </td>
                <td>
                  <span class="shw">De: </span>{{ cli.NCLIENTETRANSDE.NOMBRE }}
                </td>
                <td>
                  <span class="shw">Para: </span
                  >{{ cli.NCLIENTETRANSPARA.NOMBRE }}
                </td>
                <td>
                  <span class="shw">Cantidad: </span>
                  {{
                    cli.TIPO
                      ? (cli.QTRAN | currency: "Q. ")
                      : (cli.CMONEDA | currency: cli.MONEDA + " ")
                  }}
                </td>
                <td>
                  <span class="shw">Ganancia: </span
                  >{{ cli.GAN | currency: "Q. " }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</p-card>
