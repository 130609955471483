import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddClienteComponent } from './cliente/add-cliente/add-cliente.component';
import { ClientesComponent } from './cliente/clientes/clientes.component';
import { MonedaManagerComponent } from './moneda/moneda-manager/moneda-manager.component';
import { TransacProcesComponent } from './transaccion/transac-proces/transac-proces.component';
import { TransaccionesComponent } from './transaccion/transacciones/transacciones.component';
import { ViewClienteComponent } from './cliente/view-cliente/view-cliente.component';
import { TransaccionComponent } from './transaccion/transaccion/transaccion.component';
import { DiarioComponent } from './consulta/diario/diario.component';
import { LoginComponent } from './login/login/login.component';

const routes: Routes = [
  {
    path:'',
    component:ClientesComponent
  },
  {
    path: 'addCliente',
    component: AddClienteComponent,
  },
  {
    path:'Clientes',
    component: ClientesComponent
  },
  {
    path:'Cliente',
    component:ViewClienteComponent
  },
  {
    path:'Transacciones',
    component:TransaccionesComponent
  },{
    path:'Transaccion',
    component:TransaccionComponent,
  },
  {
    path:'Transaccione',
    component:TransaccionComponent
  },
  {
    path:'addTransaccion',
    component:TransacProcesComponent
  },
  {
    path: 'monedaManager',
    component:MonedaManagerComponent
  },{
    path:'diario',
    component:DiarioComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
