import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Moneda } from 'src/app/models/moneda';
import { MonedaxCLiente } from 'src/app/models/monedaxCliente';
import { Transaccion } from 'src/app/models/transaccion';
import { ControllerServicioService } from 'src/app/servicios/controller-servicio.service';

@Component({
  selector: 'app-edit-transacccion',
  templateUrl: './edit-transacccion.component.html',
  styleUrls: ['./edit-transacccion.component.scss'],
  providers: [MessageService],
})
export class EditTransacccionComponent implements OnInit {
  blocked: boolean = false;
  trans: Transaccion = new Transaccion();
  ticketAdd: string = '';

  //MONEDAS PARA EL CLIENTE
  monedasG: Moneda[] = [];
  monedaSelected: Moneda = new Moneda();
  monedaDefecto: boolean = false;

  //variable donde se guarda la transaccion encontrada al agregar una boleta
  transBDetected: Transaccion = new Transaccion();

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private ctrlService: ControllerServicioService,
    private Ruta: Router
  ) {
    this.trans = config.data.trans;
    this.ctrlService.getMonedas().subscribe((res) => {
      this.monedasG = this.convertToBoolean(res);
      var monedaTemp = new Moneda();
      monedaTemp.NOMBRE = 'Seleccione uno';
      this.monedasG.unshift(monedaTemp);
      this.monedasG.forEach((moneda) => {
        if (moneda.NOMBRE == this.trans.MONEDA) {
          this.monedaSelected = moneda;
        }
      });
    });
  }

  ngOnInit(): void {}

  //AGREGA Y COMPRUEBA SI LA BOLETA EXISTE
  addAndCheck() {
    this.blocked = true;
    this.ctrlService.getTestBoletaByID(this.ticketAdd).subscribe((res) => {
      if (this.trans.BOLETAS.find((e) => e === this.ticketAdd)) {
        this.messageService.add({
          severity: 'error',
          summary: 'Mensaje del sistema',
          detail: 'La Boleta ya está ingresada entre las boletas',
          data: { idtrans: false },
        });
        this.blocked = false;
      } else if (res) {
        this.ctrlService.getTransaccionByID(res.TRANSACCION).subscribe((rs) => {
          this.ctrlService.getCliente(rs.DE).subscribe((resDe) => {
            rs.NCLIENTETRANSDE = resDe;
            this.ctrlService.getCliente(rs.PARA).subscribe((resPara) => {
              rs.NCLIENTETRANSPARA = resPara;
              this.transBDetected = rs;
              if (this.trans.ID === this.transBDetected.ID) {
                this.trans.BOLETAS.push(this.ticketAdd);
                this.ticketAdd = '';
                this.blocked = false;
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Mensaje del sistema',
                  detail: 'La Boleta ya está ingresada en el sistema',
                  data: { idtrans: true },
                });
                this.blocked = false;
              }
            });
          });
        });
      } else if (this.ticketAdd === '') {
        this.messageService.add({
          severity: 'error',
          summary: 'Mensaje del sistema',
          detail: 'La Boleta no debe estar en blanco',
          data: { idtrans: false },
        });
        this.blocked = false;
      } else {
        this.trans.BOLETAS.push(this.ticketAdd);
        this.ticketAdd = '';
        this.blocked = false;
      }
    });
  }

  //REMUEVE LA BOLETA
  removeBoleto(index: number) {
    this.trans.BOLETAS.splice(index, 1);
  }

  verTransaccion() {
    this.ctrlService
      .getTestBoletaByTrans(this.transBDetected.ID)
      .subscribe((res) => {
        this.transBDetected.BOLETAS = [];
        res.forEach((boleta) => {
          this.transBDetected.BOLETAS.push(boleta.BOLETA);
        });
        this.ctrlService.setTransaccionSelected(this.transBDetected);
        if (this.Ruta.url === '/Transaccion') {
          this.ref.close(false);
          this.Ruta.navigateByUrl('/Transaccione');
        } else {
          this.ref.close(false);
          this.Ruta.navigateByUrl('/Transaccion');
        }
      });
  }

  closeDialog(save: boolean) {
    this.ref.close(save);
    if (this.Ruta.url === '/Transaccion') {
      this.ref.close(false);
      this.Ruta.navigateByUrl('/Transaccione');
    } else {
      this.ref.close(false);
      this.Ruta.navigateByUrl('/Transaccion');
    }
  }

  actualizarTransaccion() {
    this.blocked = true;
    if (this.trans.TIPO == 0) {
      if (
        this.trans.FCOM === 0 ||
        this.trans.FVEN === 0
      ) {
        this.messageService.add({
          severity: 'error',
          summary: 'Mensaje del sistema',
          detail: 'Llene los campos para completar la transacción',
          data: { idtrans: false },
        });
      } else {
        this.trans.MONEDA = this.monedaSelected.NOMBRE;
        this.trans.QCOM = this.trans.FCOMT
          ? this.trans.CMONEDA / this.trans.FCOM
          : this.trans.CMONEDA * this.trans.FCOM;
        this.trans.QCOM =
          Math.round((this.trans.QCOM + Number.EPSILON) * 100) / 100;
        this.trans.QVEN = this.trans.FVENT
          ? this.trans.CMONEDA / this.trans.FVEN
          : this.trans.CMONEDA * this.trans.FVEN;
        this.trans.QVEN =
          Math.round((this.trans.QVEN + Number.EPSILON) * 100) / 100;
        this.trans.GAN = this.trans.QVEN - this.trans.QCOM;
        this.ctrlService.actualizarTransaccion(this.trans).subscribe((res) => {
          if (res) {
            this.ctrlService.setTransaccionSelected(this.trans);
            if (this.Ruta.url === '/Transaccion') {
              this.ref.close(false);
              this.Ruta.navigateByUrl('/Transaccione');
            } else {
              this.ref.close(false);
              this.Ruta.navigateByUrl('/Transaccion');
            }
          }
        });
      }
    } else {
      this.ctrlService.actualizarTransaccion(this.trans).subscribe((res) => {
        if (res) {
          this.ctrlService.setTransaccionSelected(this.trans);
          if (this.Ruta.url === '/Transaccion') {
            this.ref.close(false);
            this.Ruta.navigateByUrl('/Transaccione');
          } else {
            this.ref.close(false);
            this.Ruta.navigateByUrl('/Transaccion');
          }
        }
      });
    }
  }

  updateInfo() {
    this.setFactores();
  }
  setFactores() {
    if (!this.monedaDefecto) {
      this.ctrlService
        .buscarMonedaxClienteByIDNombre(
          this.monedaSelected.NOMBRE,
          this.trans.NCLIENTETRANSDE.ID
        )
        .subscribe((res) => {
          var monedaPersoDE: MonedaxCLiente = res || new MonedaxCLiente();
          //CARGA LA CONFIGURACION PERSONALIZADA DEL CLIENTE COMPRADOR
          if (monedaPersoDE.NOMBRE === '') {
            this.trans.FCOM = this.monedaSelected.FACCOMPRA;
            this.trans.FCOMT = this.monedaSelected.OPERADORCOMPRA;
          } else {
            this.trans.FCOM = monedaPersoDE.FACCOMPRA;
            this.trans.FCOMT = !!Number(monedaPersoDE.OPERADORCOMPRA);
          }
        });

      this.ctrlService
        .buscarMonedaxClienteByIDNombre(
          this.monedaSelected.NOMBRE,
          this.trans.NCLIENTETRANSPARA.ID
        )
        .subscribe((res) => {
          var monedaPersoPara: MonedaxCLiente = res || new MonedaxCLiente();
          //CARGA LA CONFIGURACION PERSONALIZADA DEL CLIENTE VENDEDOR
          if (monedaPersoPara.NOMBRE === '') {
            this.trans.FVEN = this.monedaSelected.FACVENTA;
            this.trans.FVENT = this.monedaSelected.OPERADORVENTA;
          } else {
            this.trans.FVEN = monedaPersoPara.FACVENTA;
            this.trans.FVENT = !!Number(monedaPersoPara.OPERADORVENTA);
          }
        });
    } else {
      this.trans.FCOM = this.monedaSelected.FACCOMPRA;
      this.trans.FCOMT = this.monedaSelected.OPERADORCOMPRA;
      this.trans.FVEN = this.monedaSelected.FACVENTA;
      this.trans.FVENT = this.monedaSelected.OPERADORVENTA;
    }
  }

  convertToBoolean(montemp: any[]): any[] {
    for (let index = 0; index < montemp.length; index++) {
      montemp[index].OPERADORCOMPRA = !!Number(montemp[index].OPERADORCOMPRA);
      montemp[index].OPERADORVENTA = !!Number(montemp[index].OPERADORVENTA);
    }
    return montemp;
  }
}
