<p-blockUI [blocked]="blocked" class="fixMask">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
<p-card>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-col-12">
      <h2>
        Datos del cliente
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-pencil"
          class="p-button-secondary p-button-sm"
          (click)="editarCliente()"
        ></button>
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-dollar"
          class="p-button-warning p-button-sm p-ml-2"
          (click)="monedaCliente()"
        ></button>
      </h2>
    </div>
    <div class="p-field p-col-12">
      <h5>
        <div class="p-text-bold">ID: {{ currentCliente.ID }}</div>
      </h5>
      <h5>
        <div class="p-text-bold">NOMBRE: {{ currentCliente.NOMBRE }}</div>
      </h5>
      <h5>
        <div class="p-text-bold">TELEFONO: {{ currentCliente.TELEFONO }}</div>
      </h5>
      <h5>
        <div
          class="p-text-bold"
          [ngClass]="
            currentCliente.SALDO > 0 ? 'colorTextGreen' : 'colorTextRed'
          "
        >
          SALDO: {{ currentCliente.SALDO | currency : "Q" }}
        </div>
      </h5>
    </div>
    <div class="p-col-12">
      <h2>Historial de transferencias</h2>
    </div>
    <div class="p-col-12">
      <p-divider></p-divider>
    </div>
    <div class="p-col-12 mt-1N">
      <h3>Filtrar</h3>
    </div>
    <div class="p-col-12 p-grid">
      <div class="p-field p-col-12 p-lg-2">
        <label for="startDate">Fecha inicial</label>
        <p-calendar
          [(ngModel)]="startDate"
          dateFormat="yy-mm-dd"
          inputId="startDate"
        ></p-calendar>
      </div>
      <div class="p-field p-col-12 p-lg-2">
        <label for="endDate">Fecha final</label>
        <p-calendar
          [(ngModel)]="endDate"
          dateFormat="yy-mm-dd"
          inputId="endDate"
        ></p-calendar>
      </div>
      <div class="p-field p-col-12 p-lg-2">
        <label for="filterOption">Estado</label>
        <p-dropdown
          id="filterOption"
          [options]="optionsFilter"
          [(ngModel)]="optionFilter"
        ></p-dropdown>
      </div>
      <div class="p-mt-4 p-md-1 p-lg-1">
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-filter"
          class="p-button-primary p-button-sm"
          (click)="cargarMovimientos()"
        ></button>
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-refresh"
          class="p-button-success p-button-sm p-ml-2"
          (click)="reiniciar()"
        ></button>
      </div>
    </div>
    <div class="p-col-12">
      <p-divider></p-divider>
    </div>
    <p-tabView>
      <p-tabPanel header="Locales">
        <p-table
          [value]="movLocal"
          styleClass="p-datatable-sm p-datatable-responsive-demo"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>ID</th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">Fecha</div>
              </th>
              <th>Estado</th>
              <th>Destinatario</th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  Cliente
                  <p-columnFilter
                    type="text"
                    field="NCLIENTETRANS.NOMBRE"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </th>
              <th>Banco</th>
              <th>Saldo anterior</th>
              <th>Cantidad</th>
              <th>Saldo nuevo</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-cli>
            <tr class="manita" (click)="selectTransaccion(cli)">
              <td class="blackText">{{ cli.ID }}</td>
              <td>{{ cli.FECHA | date : "MM/dd/yy" }}</td>
              <td>{{ cli.ESTADO ? "RECIBIDA" : "ENVIADA" }}</td>
              <td>{{ cli.DESTINATARIO }}</td>
              <td>{{ cli.NCLIENTETRANS.NOMBRE }}</td>
              <td>{{ cli.BANCO }}</td>
              <td>{{ cli.SALDOA | currency : "Q" }}</td>
              <td>{{ cli.CANTIDAD | currency : "Q" }}</td>
              <td>{{ cli.SALDON | currency : "Q" }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr *ngFor="let item of cantidadesPorMonedaArray">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td><strong>Total</strong></td>
              <td>{{ item.cantidad | currency : "Q " }}</td>
              <td></td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>

      <p-tabPanel header="Extranjeras">
        <p-table
          [value]="movExtranjero"
          styleClass="p-datatable-sm p-datatable-responsive-demo"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>ID</th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">Fecha</div>
              </th>
              <th>Estado</th>
              <th>Destinatario</th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  Cliente
                  <p-columnFilter
                    type="text"
                    field="NCLIENTETRANS.NOMBRE"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </th>
              <th>Banco</th>
              <th>Saldo anterior</th>
              <th>Moneda</th>
              <th>Cantidad</th>
              <th>Saldo nuevo</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-cli>
            <tr class="manita" (click)="selectTransaccion(cli)">
              <td class="blackText">{{ cli.ID }}</td>
              <td>{{ cli.FECHA | date : "MM/dd/yy" }}</td>
              <td>{{ cli.ESTADO ? "RECIBIDA" : "ENVIADA" }}</td>
              <td>{{ cli.DESTINATARIO }}</td>
              <td>{{ cli.NCLIENTETRANS.NOMBRE }}</td>
              <td>{{ cli.BANCO }}</td>
              <td>{{ cli.SALDOA | currency : "Q" }}</td>
              <td>{{ cli.MONEDA }}</td>
              <td>{{ cli.CANTIDAD }}</td>
              <td>{{ cli.SALDON | currency : "Q" }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr *ngFor="let item of cantidadesPorMonedaExtranjeroArray">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td><strong>Total</strong></td>
              <td>{{ item.cantidad | currency : item.moneda + " " }}</td>
              <td></td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>

      <p-tabPanel header="Gastos">
        <p-table
          [value]="movGasto"
          styleClass="p-datatable-sm p-datatable-responsive-demo"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>ID</th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">Fecha</div>
              </th>
              <th>Estado</th>
              <th>Destinatario</th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  Cliente
                  <p-columnFilter
                    type="text"
                    field="NCLIENTETRANS.NOMBRE"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </th>
              <th>Banco</th>
              <th>Saldo anterior</th>
              <th>Cantidad</th>
              <th>Saldo nuevo</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-cli>
            <tr class="manita" (click)="selectTransaccion(cli)">
              <td class="blackText">{{ cli.ID }}</td>
              <td>{{ cli.FECHA | date : "MM/dd/yy" }}</td>
              <td>{{ cli.ESTADO ? "RECIBIDA" : "ENVIADA" }}</td>
              <td>{{ cli.DESTINATARIO }}</td>
              <td>{{ cli.NCLIENTETRANS.NOMBRE }}</td>
              <td>{{ cli.BANCO }}</td>
              <td>{{ cli.SALDOA | currency : "Q" }}</td>
              <td>{{ cli.CANTIDAD | currency : "Q" }}</td>
              <td>{{ cli.SALDON | currency : "Q" }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr *ngIf="movGasto.length > 0">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td><strong>Total</strong></td>
              <td>{{ calcularTotalGasto() | currency : "Q" }}</td>
              <td></td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>
    </p-tabView>
  </div>
</p-card>
