import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { EditClienteComponent } from '../edit-cliente/edit-cliente.component';
import { Cliente } from '../../models/cliente';
import { ControllerServicioService } from '../../servicios/controller-servicio.service';
import { MonedaClienteComponent } from '../moneda-cliente/moneda-cliente.component';
import { Movimiento } from 'src/app/models/movimiento';
import { Transaccion } from 'src/app/models/transaccion';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-view-cliente',
  templateUrl: './view-cliente.component.html',
  styleUrls: ['./view-cliente.component.scss'],
  providers: [DialogService],
})
export class ViewClienteComponent implements OnInit {
  currentCliente: Cliente;
  saldoB = true;

  movLocal: Movimiento[] = [];
  movExtranjero: Movimiento[] = [];
  movGasto: Movimiento[] = [];

  cantidadesPorMonedaLocal = new Map<string, number>();
  cantidadesPorMonedaArray = Array.from(
    this.cantidadesPorMonedaLocal,
    ([moneda, cantidad]) => ({ moneda, cantidad })
  );

  cantidadesPorMonedaExtranjero = new Map<string, number>();
  cantidadesPorMonedaExtranjeroArray = Array.from(
    this.cantidadesPorMonedaExtranjero,
    ([moneda, cantidad]) => ({ moneda, cantidad })
  );

  blocked: boolean = true;

  startDate: Date = new Date();
  endDate: Date = new Date();
  optionFilter: string = 'Todos';

  optionsFilter: string[] = [];

  nMovimientos: number = 0;
  nCount: number = 0;

  constructor(
    private Ruta: Router,
    public dialogService: DialogService,
    public ctrlService: ControllerServicioService
  ) {
    this.startDate.setDate(this.startDate.getDate() - 1);
    this.blocked = true;
    this.optionsFilter = ['Todos', 'Recibidos', 'Enviados'];
    this.currentCliente = ctrlService.getSelectedCliente();
    if (this.currentCliente.SALDO > 0) {
      this.saldoB = true;
    } else {
      this.saldoB = false;
    }
    this.cargarMovimientos();
  }
  ngOnInit(): void {}

  editarCliente() {
    const ref = this.dialogService.open(EditClienteComponent, {
      data: {},
      header: 'Editando cliente',
      width: '100vw',
    });

    ref.onClose.subscribe((res) => {
      if (res) {
        this.updateView();
      } else {
        console.log('Cerrando Modal');
      }
    });
  }

  calcularTotalLocal() {
    this.cantidadesPorMonedaLocal = new Map<string, number>();
    for (const movimiento of this.movLocal) {
      const cantidadAcumulada = this.cantidadesPorMonedaLocal.get(movimiento.MONEDA) ?? 0;
      this.cantidadesPorMonedaLocal.set(
        movimiento.MONEDA,
        cantidadAcumulada + movimiento.CANTIDAD
      );
    }
    this.cantidadesPorMonedaArray = Array.from(
      this.cantidadesPorMonedaLocal,
      ([moneda, cantidad]) => ({ moneda, cantidad })
    );
  }

  calcularTotalExtranjero() {
    this.cantidadesPorMonedaExtranjero = new Map<string, number>();
    for (const movimiento of this.movExtranjero) {
      const cantidadAcumulada = this.cantidadesPorMonedaExtranjero.get(movimiento.MONEDA) ?? 0;
      this.cantidadesPorMonedaExtranjero.set(
        movimiento.MONEDA,
        cantidadAcumulada + movimiento.CANTIDAD
      );
    }
    this.cantidadesPorMonedaExtranjeroArray = Array.from(
      this.cantidadesPorMonedaExtranjero,
      ([moneda, cantidad]) => ({ moneda, cantidad })
    );
  }

  calcularTotalGasto(): number {
    return this.movGasto
      .map((item) => item.CANTIDAD)
      .reduce((prev, curr) => prev + curr, 0);
  }

  monedaCliente() {
    const ref = this.dialogService.open(MonedaClienteComponent, {
      data: { id: this.currentCliente.ID },
      header: 'Monedas del cliente',
      width: '100vw',
      contentStyle: { height: '500px', overflow: 'auto' },
      baseZIndex: 10000,
    });

    ref.onClose.subscribe((res) => {
      if (res) {
        this.updateView();
      } else {
        console.log('Cerrando Modal');
      }
    });
  }

  updateView() {
    this.currentCliente = this.ctrlService.getSelectedCliente();
  }

  selectTransaccion(cli: Movimiento) {
    this.ctrlService.getTransaccionByID(cli.TRANS).subscribe((res) => {
      var trans: Transaccion = res;
      this.ctrlService.setTransaccionSelected(trans);
      this.Ruta.navigateByUrl('/Transaccion');
    });
  }

  cargarMovimientos() {
    this.ctrlService
      .getMovimientosByCliente(
        this.currentCliente.ID,
        1,
        this.convertDateSQL(this.startDate),
        this.convertDateSQL(this.endDate),
        this.optionFilter
      )
      .subscribe(
        (resL) => {
          this.movLocal = resL;
          this.calcularTotalLocal();
          this.ctrlService
            .getMovimientosByCliente(
              this.currentCliente.ID,
              0,
              this.convertDateSQL(this.startDate),
              this.convertDateSQL(this.endDate),
              this.optionFilter
            )
            .subscribe(
              (resE) => {
                this.movExtranjero = resE;
                this.calcularTotalExtranjero();
                this.ctrlService
                  .getMovimientosByCliente(
                    this.currentCliente.ID,
                    2,
                    this.convertDateSQL(this.startDate),
                    this.convertDateSQL(this.endDate),
                    this.optionFilter
                  )
                  .subscribe(
                    (resG) => {
                      this.movGasto = resG;
                      this.blocked = false;
                    },
                    (err) => {
                      this.cargarMovimientos();
                    }
                  );
              },
              (err) => {
                this.cargarMovimientos();
              }
            );
        },
        (err) => {
          this.cargarMovimientos();
        }
      );
  }

  reiniciar() {
    this.startDate = new Date();
    this.endDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 1);
    this.optionFilter = 'Todos';
    this.cargarMovimientos();
  }

  convertDateSQL(date: Date): string {
    return moment(date).format('yyyy-MM-DD');
  }
}
