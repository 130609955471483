import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Moneda } from 'src/app/models/moneda';
import { MonedaxCLiente } from 'src/app/models/monedaxCliente';
import { ControllerServicioService } from 'src/app/servicios/controller-servicio.service';

@Component({
  selector: 'app-moneda-cliente',
  templateUrl: './moneda-cliente.component.html',
  styleUrls: ['./moneda-cliente.component.scss'],
})
export class MonedaClienteComponent implements OnInit {
  formAddGroup: FormGroup;
  monedas: Moneda[] = [];
  idCliente: number = 0;
  blocked: boolean = true;
  monedaxCliente: MonedaxCLiente[] = [];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formAdd: FormBuilder,
    public ctrlService: ControllerServicioService
  ) {
    //ID del cliente
    this.idCliente = this.config.data.id;

    //todas las monedas ingresadas
    ctrlService.getMonedas().subscribe((res) => {
      this.monedas = this.convertToBoolean(res);
      var startMoneda = new Moneda();
      startMoneda.NOMBRE = 'Seleccione una moneda';
      this.monedas.unshift(startMoneda);
    });

    //Monedas del cliente ya configuradas
    this.updateLista();

    //usado para el select

    this.formAddGroup = this.formAdd.group({
      MONEDA: [, Validators.required],
      FACCOMPRA: [, Validators.required],
      FACVENTA: [, Validators.required],
      OPERADORCOMPRA: [, Validators.required],
      OPERADORVENTA: [, Validators.required],
    });
  }

  ngOnInit(): void {}

  updateInfo() {
    this.formAddGroup.patchValue({
      FACCOMPRA: this.formAddGroup.value.MONEDA.FACCOMPRA,
      FACVENTA: this.formAddGroup.value.MONEDA.FACVENTA,
      OPERADORCOMPRA: this.formAddGroup.value.MONEDA.OPERADORCOMPRA,
      OPERADORVENTA: this.formAddGroup.value.MONEDA.OPERADORVENTA,
    });
  }

  addMonedaxCliente() {
    if (this.formAddGroup.value.MONEDA.NOMBRE !== 'Seleccione una moneda') {
      var monedaxCLiente: MonedaxCLiente = new MonedaxCLiente();
      monedaxCLiente.CLIENTEID = this.idCliente;
      monedaxCLiente.NOMBRE = this.formAddGroup.value.MONEDA.NOMBRE;
      monedaxCLiente.FACCOMPRA = this.formAddGroup.value.FACCOMPRA;
      monedaxCLiente.FACVENTA = this.formAddGroup.value.FACVENTA;
      monedaxCLiente.OPERADORCOMPRA = this.formAddGroup.value.OPERADORCOMPRA;
      monedaxCLiente.OPERADORVENTA = this.formAddGroup.value.OPERADORVENTA;
      this.ctrlService.addMonedaxCLiente(monedaxCLiente).subscribe((res) => {
        this.updateLista();
      });
    } else {
      console.log('no hace nada');
    }
  }

  updateLista() {
    this.ctrlService.getMonedasxClienteByID(this.idCliente).subscribe((res) => {
      this.monedaxCliente = this.convertToBoolean(res);
      this.blocked = false;
    });
  }
  convertToBoolean(montemp: any[]): any[] {
    for (let index = 0; index < montemp.length; index++) {
      montemp[index].OPERADORCOMPRA = !!Number(montemp[index].OPERADORCOMPRA);
      montemp[index].OPERADORVENTA = !!Number(montemp[index].OPERADORVENTA);
    }
    return montemp;
  }
  closeDialog(save: boolean) {
    this.ref.close(save);
  }
}
