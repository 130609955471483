import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { Cliente } from '../../models/cliente';
import { ControllerServicioService } from '../../servicios/controller-servicio.service';

@Component({
  selector: 'app-edit-cliente',
  templateUrl: './edit-cliente.component.html',
  styleUrls: ['./edit-cliente.component.scss'],
  providers: [DialogService],
})
export class EditClienteComponent implements OnInit {
  formAddGroup: FormGroup;
  clienteSelected: Cliente;
  stateOptions: any = [
    { label: 'Positivo', value: true },
    { label: 'Negativo', value: false },
  ];

  blocked: boolean = false;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formAdd: FormBuilder,
    public ctrlService: ControllerServicioService
  ) {
    this.clienteSelected = this.ctrlService.getSelectedCliente();
    this.formAddGroup = this.formAdd.group({
      ID: [this.clienteSelected.ID, Validators.required],
      NOMBRE: [this.clienteSelected.NOMBRE, Validators.required],
      TELEFONO: [this.clienteSelected.TELEFONO, Validators.required],
      POSNEG: [this.posNegCheck(), Validators.required],
      SALDO: [Math.abs(this.clienteSelected.SALDO), Validators.required],
      ESPECIAL: [this.clienteSelected.ESPECIAL, Validators.required],
    });
  }

  ngOnInit(): void {}

  posNegCheck() {
    if (this.clienteSelected.SALDO > 0) {
      return { label: 'Positivo', value: true };
    } else {
      return { label: 'Negativo', value: false };
    }
  }

  guardarCliente() {
    this.blocked = true;
    let cliente: Cliente = new Cliente();
    cliente.ID = this.formAddGroup.value.ID;
    cliente.NOMBRE = this.formAddGroup.value.NOMBRE;
    cliente.TELEFONO = this.formAddGroup.value.TELEFONO;
    cliente.SALDO = this.formAddGroup.value.SALDO;

    if (this.formAddGroup.value.POSNEG.value) {
      cliente.SALDO = Math.abs(cliente.SALDO);
    } else {
      cliente.SALDO = Math.abs(cliente.SALDO) * -1;
    }

    this.ctrlService.actualizarCLiente(cliente).subscribe((res) => {
      if (res) {
        this.ctrlService.setSelectedCliente(cliente);
        this.closeDialog(true);
      }
    });
  }

  closeDialog(save: boolean) {
    this.ref.close(save);
  }
}
