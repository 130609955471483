import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Transaccion } from 'src/app/models/transaccion';
import { ControllerServicioService } from 'src/app/servicios/controller-servicio.service';
import { EditTransacccionComponent } from './edit-transacccion/edit-transacccion.component';

@Component({
  selector: 'app-transaccion',
  templateUrl: './transaccion.component.html',
  styleUrls: ['./transaccion.component.scss'],
  providers: [ConfirmationService,MessageService,DialogService]
})
export class TransaccionComponent implements OnInit {
  transaccionSelected: Transaccion;
  images: string[] = [];

  //ruta: string = 'http://localhost/TranServer/uploads/';
  //ruta: string = 'https://transacapp.000webhostapp.com/TranServer/uploads/';
  //ruta:string = 'http://transacest.42web.io/TranServer/uploads/';

  //CINTO
  ruta:string = 'https://citransapp2023.ml/TranServer/uploads/';

  //IMAGENES
  uploadedFiles: any[] = [];

  blocked: boolean = false;

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];

  constructor(
    public ctrlService: ControllerServicioService,
    private Ruta: Router,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService
  ) {
    this.transaccionSelected = ctrlService.getTransaccionSelected();
    this.refreshImages();
    console.log(this.transaccionSelected)
  }

  refreshImages() {
    this.images = this.transaccionSelected.COMPROBANTE.split(',');
    for (let index = 0; index < this.images.length; index++) {
      this.images[index] =
        this.ruta + this.transaccionSelected.ID + '/' + this.images[index];
    }
    this.images.shift();
  }

  editarTransaccion() {
    const ref = this.dialogService.open(EditTransacccionComponent, {
      data: {trans:this.transaccionSelected},
      header: 'Editar Transaccion',
      width: '100vw',
    });

    ref.onClose.subscribe((res) => {
      if (res) {
        //this.openModalInfoCliente();
      } else {
        //this.updateClientes();
      }
    });
  }

  ngOnInit(): void {}

  //IMAGENES
  onUpload(event: any) {
    var uploadedFiles: any[] = [];
    for (let file of event.files) {
      uploadedFiles.push(file);
    }
    this.uploadedFiles = uploadedFiles;
  }

  onRemoveUpload(event: any) {
    var i: number = -1;
    for (let index = 0; index < this.uploadedFiles.length; index++) {
      if (event.file.name === this.uploadedFiles[index].name) {
        i = index;
      }
    }
    if (i !== -1) {
      this.uploadedFiles.splice(i, 1);
    }
  }

  cargarImagenes() {
    if (this.uploadedFiles.length > 0) {
      this.blocked = true;
      this.transaccionSelected.COMPROBANTE =
        this.transaccionSelected.COMPROBANTE +
        this.ctrlService.cargarImages(
          this.uploadedFiles,
          String(this.transaccionSelected.ID),
          this.transaccionSelected.IMAGEINDEX + 1
        );
      this.transaccionSelected.IMAGEINDEX =
        this.transaccionSelected.IMAGEINDEX + this.uploadedFiles.length;
      this.ctrlService.actualizarComprobante(this.transaccionSelected);
      this.ctrlService.setTransaccionSelected(this.transaccionSelected);
      setTimeout(() => {
        if(this.Ruta.url==='/Transaccion'){
          this.Ruta.navigateByUrl('/Transaccione');
        }else{
          this.Ruta.navigateByUrl('/Transaccion');
        }
        this.blocked = false;
      }, 3000);
    }
  }

  eliminarTransaccion(){
    this.blocked=true;
    this.ctrlService.eliminarTransaccion(this.transaccionSelected).subscribe(res=>{
      if(res){
        setTimeout(() => {
          this.Ruta.navigateByUrl('/Transacciones');
        }, 3000);
      }
    });
  }

  confirm() {
    this.confirmationService.confirm({
        message: 'Todas las informacion ingresada será eliminada permanentemente y se revertirán los saldos de los clientes afectados. ¿Está seguro de eliminar esta transacción?',
        accept: () => {
          this.eliminarTransaccion();
        }
    });
}
}
