import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { AddClienteComponent } from 'src/app/cliente/add-cliente/add-cliente.component';
import { Moneda } from 'src/app/models/moneda';
import { MonedaxCLiente } from 'src/app/models/monedaxCliente';
import { Cliente } from '../../models/cliente';
import { Transaccion } from '../../models/transaccion';
import { ControllerServicioService } from '../../servicios/controller-servicio.service';

@Component({
  selector: 'app-transac-proces',
  templateUrl: './transac-proces.component.html',
  styleUrls: ['./transac-proces.component.scss'],
  providers: [MessageService, DialogService],
})
export class TransacProcesComponent implements OnInit {
  stateOptions: any[];
  trans: Transaccion = new Transaccion();
  clienteDe: Cliente = new Cliente();
  clientePara: Cliente = new Cliente();
  block: boolean = false;

  //RESULTADO DE BUSQUEDA DE CLIENTES
  clientes: Cliente[] = [];
  clienteResultDE: Cliente[] = [];
  clienteResultPA: Cliente[] = [];
  ticketAdd: string = '';

  activeForm: boolean = false;
  activeFormGasto: boolean = false;

  //MONEDAS PARA EL CLIENTE
  monedasG: Moneda[] = [];
  monedaSelected: Moneda = new Moneda();
  monedaDefecto: boolean = false;

  //IMAGENES
  uploadedFiles: any[] = [];

  transBDetected: Transaccion = new Transaccion();

  constructor(
    private ctrlService: ControllerServicioService,
    private Ruta: Router,
    private messageService: MessageService,
    public dialogService: DialogService
  ) {
    this.stateOptions = [
      { label: 'Transacción Local', value: true },
      { label: 'Transacción Extranjera', value: false },
    ];
    this.ctrlService.allClientes().subscribe((res) => {
      this.clientes = res;
    });
    this.ctrlService.getMonedas().subscribe((res) => {
      this.monedasG = this.convertToBoolean(res);
      var monedaTemp = new Moneda();
      monedaTemp.NOMBRE = 'Seleccione uno';
      this.monedasG.unshift(monedaTemp);
    });
  }

  convertToBoolean(montemp: any[]): any[] {
    for (let index = 0; index < montemp.length; index++) {
      montemp[index].OPERADORCOMPRA = !!Number(montemp[index].OPERADORCOMPRA);
      montemp[index].OPERADORVENTA = !!Number(montemp[index].OPERADORVENTA);
    }
    return montemp;
  }

  ngOnInit(): void {}

  updateActiveForm() {
    if (this.trans.TIPO != 2) {
      if (this.clienteDe.ID === 0 || this.clientePara.ID === 0) {
        this.activeForm = false;
      } else {
        this.activeForm = true;
      }
    } else {
      if (this.clienteDe.ID === 0) {
        this.activeFormGasto = false;
      } else {
        this.activeFormGasto = true;
      }
    }
  }

  agregarCliente() {
    console.log('Agregar Cliente');
    const ref = this.dialogService.open(AddClienteComponent, {
      data: {},
      header: 'Cliente Nuevo',
      width: '100vw',
    });
    ref.onClose.subscribe((res) => {
      this.ctrlService.allClientes().subscribe((res) => {
        this.clientes = res;
      });
    });
  }

  //BUSCA CLIENTERS DE
  buscarClienteDe(event: any) {
    const search = event.query.toUpperCase();
    this.clienteResultDE = this.clientes.filter((e) => {
      const entries = Object.entries(e);
      return entries.some((entry) =>
        entry[1].toString().toUpperCase().includes(search)
      );
    });
  }

  //BUSCA CLIENTES PARA
  buscarClientePa(event: any) {
    const search = event.query.toUpperCase();
    this.clienteResultPA = this.clientes.filter((e) => {
      const entries = Object.entries(e);
      return entries.some((entry) =>
        entry[1].toString().toUpperCase().includes(search)
      );
    });
  }

  //AGREGA Y COMPRUEBA SI LA BOLETA EXISTE
  addAndCheck() {
    this.block = true;
    this.ctrlService.getTestBoletaByID(this.ticketAdd).subscribe((res) => {
      if (res) {
        this.ctrlService.getTransaccionByID(res.TRANSACCION).subscribe((rs) => {
          this.ctrlService.getCliente(rs.DE).subscribe((resDe) => {
            rs.NCLIENTETRANSDE = resDe;
            this.ctrlService.getCliente(rs.PARA).subscribe((resPara) => {
              rs.NCLIENTETRANSPARA = resPara;
              this.transBDetected = rs;
              this.messageService.add({
                severity: 'error',
                summary: 'Mensaje del sistema',
                detail: 'La Boleta ya está ingresada en el sistema',
                data: { idtrans: true },
              });
              this.block = false;
            });
          });
        });
      } else if (this.trans.BOLETAS.find((e) => e === this.ticketAdd)) {
        this.messageService.add({
          severity: 'error',
          summary: 'Mensaje del sistema',
          detail: 'La Boleta ya está ingresada entre las boletas',
          data: { idtrans: false },
        });
        this.block = false;
      } else if (this.ticketAdd === '') {
        this.messageService.add({
          severity: 'error',
          summary: 'Mensaje del sistema',
          detail: 'La Boleta no debe estar en blanco',
          data: { idtrans: false },
        });
        this.block = false;
      } else {
        this.trans.BOLETAS.push(this.ticketAdd);
        this.ticketAdd = '';
        this.block = false;
      }
    });
  }

  verTransaccion() {
    this.ctrlService
      .getTestBoletaByTrans(this.transBDetected.ID)
      .subscribe((res) => {
        this.transBDetected.BOLETAS = [];
        res.forEach((boleta) => {
          this.transBDetected.BOLETAS.push(boleta.BOLETA);
        });
        this.ctrlService.setTransaccionSelected(this.transBDetected);
        this.Ruta.navigateByUrl('/Transaccion');
      });
  }

  //REMUEVE LA BOLETA
  removeBoleto(index: number) {
    this.trans.BOLETAS.splice(index, 1);
  }

  //AGREGA LA TRANSACCION
  agregarTransaccion() {
    this.trans.DE = this.clienteDe.ID;
    this.trans.PARA = this.clientePara.ID;
    if (this.trans.TIPO == 1) {
      if (
        this.trans.DE === 0 ||
        this.trans.PARA === 0 ||
        this.trans.QTRAN == 0
      ) {
        this.messageService.add({
          severity: 'error',
          summary: 'Mensaje del sistema',
          detail: 'Llene los campos para completar la transacción',
          data: { idtrans: false },
        });
      } else {
        //AGREGA TRANSACCION
        this.addTransaccion(this.trans, this.uploadedFiles);
      }
    } else if (this.trans.TIPO == 0) {
      if (
        this.trans.DE === 0 ||
        this.trans.PARA === 0 ||
        this.trans.FCOM === 0 ||
        this.trans.FVEN === 0
      ) {
        this.messageService.add({
          severity: 'error',
          summary: 'Mensaje del sistema',
          detail: 'Llene los campos para completar la transacción',
          data: { idtrans: false },
        });
      } else {
        this.trans.MONEDA = this.monedaSelected.NOMBRE;
        this.trans.QCOM = this.trans.FCOMT
          ? this.trans.CMONEDA / this.trans.FCOM
          : this.trans.CMONEDA * this.trans.FCOM;
        this.trans.QCOM =
          Math.round((this.trans.QCOM + Number.EPSILON) * 100) / 100;
        this.trans.QVEN = this.trans.FVENT
          ? this.trans.CMONEDA / this.trans.FVEN
          : this.trans.CMONEDA * this.trans.FVEN;
        this.trans.QVEN =
          Math.round((this.trans.QVEN + Number.EPSILON) * 100) / 100;
        this.trans.GAN = this.trans.QVEN - this.trans.QCOM;
        //AGREGA TRANSACCION
        this.addTransaccion(this.trans, this.uploadedFiles);
      }
    } else {
      if (
        this.trans.DE === 0 ||
        this.trans.QTRAN == 0 ||
        this.trans.DESCRIPCION == ''
      ) {
        this.messageService.add({
          severity: 'error',
          summary: 'Mensaje del sistema',
          detail: 'Llene los campos para completar la transacción',
          data: { idtrans: false },
        });
      } else {
        //AGREGA TRANSACCION
        this.trans.PARA = 0;
        this.addTransaccion(this.trans, this.uploadedFiles);
      }
    }
  }

  //CANCELA LA TRANSACCION Y REGRESA A LA VENTANA ANTERIOR
  cancelarTransaccion() {
    this.Ruta.navigateByUrl('/Transacciones');
  }

  //AL SELECCIONAR MONEDA CARGA LA CONFIGURACION PARA EL CLIENTE
  updateInfo() {
    this.setFactores();
  }

  setFactores() {
    if (!this.monedaDefecto) {
      this.ctrlService
        .buscarMonedaxClienteByIDNombre(
          this.monedaSelected.NOMBRE,
          this.clienteDe.ID
        )
        .subscribe((res) => {
          var monedaPersoDE: MonedaxCLiente = res || new MonedaxCLiente();
          //CARGA LA CONFIGURACION PERSONALIZADA DEL CLIENTE COMPRADOR
          if (monedaPersoDE.NOMBRE === '') {
            this.trans.FCOM = this.monedaSelected.FACCOMPRA;
            this.trans.FCOMT = this.monedaSelected.OPERADORCOMPRA;
          } else {
            this.trans.FCOM = monedaPersoDE.FACCOMPRA;
            this.trans.FCOMT = !!Number(monedaPersoDE.OPERADORCOMPRA);
          }
        });

      this.ctrlService
        .buscarMonedaxClienteByIDNombre(
          this.monedaSelected.NOMBRE,
          this.clientePara.ID
        )
        .subscribe((res) => {
          var monedaPersoPara: MonedaxCLiente = res || new MonedaxCLiente();
          //CARGA LA CONFIGURACION PERSONALIZADA DEL CLIENTE VENDEDOR
          if (monedaPersoPara.NOMBRE === '') {
            this.trans.FVEN = this.monedaSelected.FACVENTA;
            this.trans.FVENT = this.monedaSelected.OPERADORVENTA;
          } else {
            this.trans.FVEN = monedaPersoPara.FACVENTA;
            this.trans.FVENT = !!Number(monedaPersoPara.OPERADORVENTA);
          }
        });
    } else {
      this.trans.FCOM = this.monedaSelected.FACCOMPRA;
      this.trans.FCOMT = this.monedaSelected.OPERADORCOMPRA;
      this.trans.FVEN = this.monedaSelected.FACVENTA;
      this.trans.FVENT = this.monedaSelected.OPERADORVENTA;
    }
  }

  //IMAGENES
  onUpload(event: any) {
    var uploadedFiles: any[] = [];
    for (let file of event.files) {
      uploadedFiles.push(file);
    }
    this.uploadedFiles = uploadedFiles;
  }

  onRemoveUpload(event: any) {
    var i: number = -1;
    for (let index = 0; index < this.uploadedFiles.length; index++) {
      if (event.file.name === this.uploadedFiles[index].name) {
        i = index;
      }
    }
    if (i !== -1) {
      this.uploadedFiles.splice(i, 1);
    }
  }

  addTransaccion(trans: Transaccion, images: any[]) {
    this.block = true;
    this.ctrlService.crearTransacciones(trans).subscribe((res) => {
      //Carga el id de la transaccion
      var idtrans: number = res;
      trans.ID = idtrans;
      trans.IMAGEINDEX = images.length - 1;
      trans.COMPROBANTE = this.ctrlService.cargarImages(
        images,
        String(idtrans),
        0
      );
      this.ctrlService.actualizarComprobante(trans);

      if (trans.TIPO == 0) {
        this.ctrlService.updateMonedaxClienteTrans(trans);
      }
      setTimeout(() => {
        this.Ruta.navigateByUrl('/Transacciones');
      }, 3000);
    });
  }
}
