import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Cliente } from '../../models/cliente';
import { ControllerServicioService } from '../../servicios/controller-servicio.service';

@Component({
  selector: 'app-add-cliente',
  templateUrl: './add-cliente.component.html',
  styleUrls: ['./add-cliente.component.scss'],
})
export class AddClienteComponent implements OnInit {
  formAddGroup: FormGroup;
  stateOptions: any = [
    { label: 'Positivo', value: true },
    { label: 'Negativo', value: false },
  ];
  blocked: boolean = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formAdd: FormBuilder,
    public ctrlService: ControllerServicioService
  ) {
    this.formAddGroup = this.formAdd.group({
      NOMBRE: ['', Validators.required],
      TELEFONO: ['', Validators.required],
      SALDO: [, Validators.required],
      POSNEG: [true, Validators.required],
    });
  }

  ngOnInit(): void {}

  addCliente() {
    this.blocked = true;
    let cliente: Cliente = new Cliente();
    cliente.NOMBRE = this.formAddGroup.value.NOMBRE;
    cliente.TELEFONO = this.formAddGroup.value.TELEFONO;
    cliente.SALDO = this.formAddGroup.value.SALDO;

    if (this.formAddGroup.value.POSNEG.value) {
      cliente.SALDO = Math.abs(cliente.SALDO);
    } else {
      cliente.SALDO = Math.abs(cliente.SALDO) * -1;
    }

    this.ctrlService.crearCliente(cliente).subscribe((res) => {
      this.ctrlService.getCliente(Number(res)).subscribe((rs) => {
        this.ctrlService.setSelectedCliente(rs);
        this.closeDialog(true);
      });
    });
  }

  closeDialog(save: boolean) {
    this.ref.close(save);
  }
}
