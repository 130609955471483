import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { AddMonedaComponent } from '../add-moneda/add-moneda.component';
import { EditMonedaComponent } from '../edit-moneda/edit-moneda.component';
import { Moneda } from '../../models/moneda';
import { ControllerServicioService } from '../../servicios/controller-servicio.service';

@Component({
  selector: 'app-moneda-manager',
  templateUrl: './moneda-manager.component.html',
  styleUrls: ['./moneda-manager.component.scss'],
  providers: [DialogService],
})
export class MonedaManagerComponent implements OnInit {
  monedas: Moneda[] = [];
  blocked: boolean = true;
  constructor(
    public dialogService: DialogService,
    private ctrlService: ControllerServicioService
  ) {
    this.updateMonedasList();
  }

  ngOnInit(): void {}

  agregarMoenda() {
    const ref = this.dialogService.open(AddMonedaComponent, {
      data: {},
      header: 'Agregar Moneda',
      width: '100vw',
    });

    ref.onClose.subscribe((res) => {
      if (res) {
        console.log('Cliente agregado');
        this.updateMonedasList();
      } else {
      }
    });
  }

  editarMoneda(moneda: Moneda) {
    this.ctrlService.setSelectedMoneda(moneda);
    const ref = this.dialogService.open(EditMonedaComponent, {
      data: {},
      header: 'Editando Moneda',
      width: '100vw',
    });

    ref.onClose.subscribe((res) => {
      if (res) {
        this.updateMonedasList();
      } else {
      }
    });
  }

  updateMonedasList() {
    this.ctrlService.getMonedas().subscribe(
      (res) => {
        this.monedas = this.convertToBoolean(res);
        this.blocked = false;
      },
      (err) => {
        this.updateMonedasList();
      }
    );
  }

  convertToBoolean(montemp: Moneda[]): Moneda[] {
    for (let index = 0; index < montemp.length; index++) {
      montemp[index].OPERADORCOMPRA = !!Number(montemp[index].OPERADORCOMPRA);
      montemp[index].OPERADORVENTA = !!Number(montemp[index].OPERADORVENTA);
    }
    return montemp;
  }
}
