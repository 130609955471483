<p-blockUI [blocked]="blocked" class="fixMask">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
<form [formGroup]="formAddGroup">
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-col-12">
      <div class="p-field">
        <label for="nombre">Nombre</label>
        <input
          id="nombre"
          type="text"
          class="p-inputtext-sm"
          formControlName="NOMBRE"
          pInputText
        />
      </div>
    </div>
    <div class="p-field p-col-3">
      <label for="faccompra">Factor de Compra</label>
      <p-inputNumber
        id="faccompra"
        mode="decimal"
        [useGrouping]="false"
        class="p-inputtext-sm"
        formControlName="FACCOMPRA"
        locale="en-US"
        [minFractionDigits]="2"
        [maxFractionDigits]="10"
      ></p-inputNumber>
    </div>
    <div class="p-field p-col-3 p-mt-5">
      <p-checkbox
        [binary]="true"
        label="Por Division"
        formControlName="OPERADORCOMPRA"
      ></p-checkbox>
    </div>
    <div class="p-field p-col-3">
      <label for="facventa">Factor de Venta</label>
      <p-inputNumber
        id="facventa"
        mode="decimal"
        [useGrouping]="false"
        class="p-inputtext-sm"
        formControlName="FACVENTA"
        locale="en-US"
        [minFractionDigits]="2"
        [maxFractionDigits]="10"
      ></p-inputNumber>
    </div>
    <div class="p-field p-col-3 p-mt-5">
      <p-checkbox
        [binary]="true"
        label="Por Division"
        formControlName="OPERADORVENTA"
      ></p-checkbox>
    </div>
    <div class="p-field p-col-12">
      <span class="p-buttonset">
        <button
          pButton
          type="button"
          label="Agregar"
          icon="pi pi-check"
          class="p-button-sm p-button-primary"
          [disabled]="!formAddGroup.valid"
          (click)="agregarMoneda()"
        ></button>
        <button
          pButton
          type="button"
          label="Cancel"
          icon="pi pi-times"
          class="p-button-sm p-button-danger p-ml-2"
          (click)="closeDialog(false)"
        ></button>
      </span>
    </div>
  </div>
</form>
