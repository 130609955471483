<p-blockUI [blocked]="blocked" class="fixMask">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
<p-confirmDialog header="Confirmacion" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-card>
  <!-- Transferencia local -->
  <div class="p-fluid p-formgrid p-grid" *ngIf="transaccionSelected.TIPO == 1">
    <div class="p-col-12">
      <h1>TRANSFERENCIA LOCAL
        <button
        pButton
        pRipple
        type="button"
        icon="pi pi-pencil"
        class="p-button-secondary p-button-sm"
        (click)="editarTransaccion()"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-times"
        class="p-button-danger p-button-sm p-ml-2"
        (click)="confirm()"
      ></button>
      </h1>
    </div>
    <div class="p-col-12 p-fluid">
      <h3 class="colorTextBlue">INFORMACION GENERAL</h3>
      <h5 class="colorTextBlack">
        ID DE TRANSFERENCIA:
        <span class="colorTextGray">{{ transaccionSelected.ID }}</span>
      </h5>
      <h5 class="colorTextBlack">
        FECHA:
        <span class="colorTextGray">{{
          transaccionSelected.FECHA | date: "dd/MM/yyyy"
        }}</span>
      </h5>
      <h5 class="colorTextBlack">
        DE:
        <span class="colorTextGray"
          >{{
            transaccionSelected.NCLIENTETRANSDE.NOMBRE
          }}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
        >
        PARA:
        <span class="colorTextGray">{{
          transaccionSelected.NCLIENTETRANSPARA.NOMBRE
        }}</span>
      </h5>
      <h5 class="colorTextBlack">
        CANTIDAD TRANSFERIDA:
        <span class="colorTextGreen">{{
          transaccionSelected.QTRAN | currency: "Q. "
        }}</span>
      </h5>
      <p-divider></p-divider>
      <h3 class="colorTextBlue">BOLETAS:</h3>
      <h5 class="colorTextBlack">
        BOLETAS:
        <span
          class="colorTextGreen"
          *ngFor="let item of transaccionSelected.BOLETAS"
          ><p-chip styleClass="custom-chip">{{ item }}</p-chip></span
        >
      </h5>
      <h5 class="colorTextBlack">
        BANCO:
        <span class="colorTextGray">{{ transaccionSelected.BANCO }}</span>
      </h5>
      <h5 class="colorTextBlack">
        DESTINATARIO:
        <span class="colorTextGray">{{
          transaccionSelected.DESTINATARIO
        }}</span>
      </h5>
      <h5 class="colorTextBlack">
        OBSERVACIONES:
        <span class="colorTextGray">{{
          transaccionSelected.OBSERVACIONES || "NINGUNA"
        }}</span>
      </h5>
      <h5 class="colorTextBlack">COMPROBANTES:</h5>
      <p-galleria
        [(value)]="images"
        [responsiveOptions]="responsiveOptions"
        [containerStyle]="{ 'max-width': '100%' }"
        [numVisible]="5"
      >
        <ng-template pTemplate="item" let-item>
          <img [src]="item" style="width: 90vw; display: block" />
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
          <div class="p-grid p-nogutter p-justify-center">
            <img [src]="item" style="height: 10vh; margin-right: 10px" />
          </div>
        </ng-template>
      </p-galleria>
    </div>
  </div>

  <!-- Transferencia extranjera -->
  <div class="p-fluid p-formgrid p-grid" *ngIf="transaccionSelected.TIPO == 0">
    <div class="p-col-12">
      <h1>Transferencia Extranjera
        <button
        pButton
        pRipple
        type="button"
        icon="pi pi-pencil"
        class="p-button-secondary p-button-sm"
        (click)="editarTransaccion()"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-times"
        class="p-button-danger p-button-sm p-ml-2"
        (click)="confirm()"
      ></button>
      </h1>
    </div>
    <div class="p-col-12 p-fluid">
      <h3 class="colorTextBlue">INFORMACION GENERAL</h3>
      <h5 class="colorTextBlack">
        ID DE TRANSFERENCIA:
        <span class="colorTextGray">{{ transaccionSelected.ID }}</span>
      </h5>
      <h5 class="colorTextBlack">
        FECHA:
        <span class="colorTextGray">{{
          transaccionSelected.FECHA | date: "dd/MM/yyyy"
        }}</span>
      </h5>
      <h5 class="colorTextBlack">
        DE:
        <span class="colorTextGray"
          >{{
            transaccionSelected.NCLIENTETRANSDE.NOMBRE
          }}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
        >
        PARA:
        <span class="colorTextGray">{{
          transaccionSelected.NCLIENTETRANSPARA.NOMBRE
        }}</span>
      </h5>
      <p-divider></p-divider>
      <h3 class="colorTextBlue">RESUMEN DE LA TRANSFERENCIA:</h3>
      <h5 class="colorTextBlack">
        MONEDA:
        <span class="colorTextGray">{{ transaccionSelected.MONEDA }}</span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CANTIDAD:
        <span class="colorTextGray">{{
          transaccionSelected.CMONEDA | currency: " "
        }}</span>
      </h5>
      <h5 class="colorTextBlack">
        COMPRA:
        <span class="colorTextRed"
          >{{ transaccionSelected.CMONEDA | currency: " "
          }}{{ transaccionSelected.FCOMT ? "/" : "x"
          }}{{ transaccionSelected.FCOM }}</span
        >
        =
        <span class="colorTextRed">{{
          transaccionSelected.QCOM | currency: "Q. "
        }}</span>
      </h5>
      <h5 class="colorTextBlack">
        VENTA:
        <span class="colorTextGreen"
          >{{ transaccionSelected.CMONEDA | currency: " "
          }}{{ transaccionSelected.FVENT ? "/" : "x"
          }}{{ transaccionSelected.FVEN }}</span
        >
        =
        <span class="colorTextGreen">{{
          transaccionSelected.QVEN | currency: "Q. "
        }}</span>
      </h5>
      <h5 class="colorTextBlack">
        GANANCIA:
        <span
          [ngClass]="
            transaccionSelected.GAN > 0 ? 'colorTextGreen' : 'colorTextRed'
          "
          >{{ transaccionSelected.GAN | currency: "Q. " }}</span
        >
      </h5>
      <p-divider></p-divider>
      <h3 class="colorTextBlue">BOLETAS:</h3>
      <h5 class="colorTextBlack">
        BOLETAS:
        <span
          class="colorTextGreen"
          *ngFor="let item of transaccionSelected.BOLETAS"
          ><p-chip styleClass="custom-chip">{{ item }}</p-chip></span
        >
      </h5>
      <h5 class="colorTextBlack">
        BANCO:
        <span class="colorTextGray">{{ transaccionSelected.BANCO }}</span>
      </h5>
      <h5 class="colorTextBlack">
        DESTINATARIO:
        <span class="colorTextGray">{{
          transaccionSelected.DESTINATARIO
        }}</span>
      </h5>
      <h5 class="colorTextBlack">
        OBSERVACIONES:
        <span class="colorTextGray">{{
          transaccionSelected.OBSERVACIONES || "NINGUNA"
        }}</span>
      </h5>
      <h5 class="colorTextBlack">COMPROBANTES:</h5>
      <p-galleria
        [(value)]="images"
        [responsiveOptions]="responsiveOptions"
        [containerStyle]="{ 'max-width': '100%' }"
        [numVisible]="5"
      >
        <ng-template pTemplate="item" let-item>
          <img [src]="item" style="width: 90vw; display: block" />
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
          <div class="p-grid p-nogutter p-justify-center">
            <img [src]="item" style="height: 10vh; margin-right: 10px" />
          </div>
        </ng-template>
      </p-galleria>
    </div>
  </div>

  <!-- Transferencia gasto -->
  <div class="p-fluid p-formgrid p-grid" *ngIf="transaccionSelected.TIPO == 2">
    <div class="p-col-12">
      <h1>TRANSFERENCIA DE PAGO 
        <button
        pButton
        pRipple
        type="button"
        icon="pi pi-pencil"
        class="p-button-secondary p-button-sm"
        (click)="editarTransaccion()"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-times"
        class="p-button-danger p-button-sm p-ml-2"
        (click)="confirm()"
      ></button>
    </h1>

    </div>
    <div class="p-col-12 p-fluid">
      <h3 class="colorTextBlue">INFORMACION GENERAL</h3>
      <h5 class="colorTextBlack">
        ID DE TRANSFERENCIA:
        <span class="colorTextGray">{{ transaccionSelected.ID }}</span>
      </h5>
      <h5 class="colorTextBlack">
        FECHA:
        <span class="colorTextGray">{{
          transaccionSelected.FECHA | date: "dd/MM/yyyy"
        }}</span>
      </h5>
      <h5 class="colorTextBlack">
        DE:
        <span class="colorTextGray"
          >{{
            transaccionSelected.NCLIENTETRANSDE.NOMBRE
          }}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
        >
        DESCRIPCION:
        <span class="colorTextGray">{{ transaccionSelected.DESCRIPCION }}</span>
      </h5>
      <h5 class="colorTextBlack">
        CANTIDAD TRANSFERIDA:
        <span class="colorTextGreen">{{
          transaccionSelected.QTRAN | currency: "Q. "
        }}</span>
      </h5>
      <p-divider></p-divider>
      <h3 class="colorTextBlue">BOLETAS:</h3>
      <h5 class="colorTextBlack">
        BOLETAS:
        <span
          class="colorTextGreen"
          *ngFor="let item of transaccionSelected.BOLETAS"
          ><p-chip styleClass="custom-chip">{{ item }}</p-chip></span
        >
      </h5>
      <h5 class="colorTextBlack">
        BANCO:
        <span class="colorTextGray">{{ transaccionSelected.BANCO }}</span>
      </h5>
      <h5 class="colorTextBlack">
        DESTINATARIO:
        <span class="colorTextGray">{{
          transaccionSelected.DESTINATARIO
        }}</span>
      </h5>
      <h5 class="colorTextBlack">
        OBSERVACIONES:
        <span class="colorTextGray">{{
          transaccionSelected.OBSERVACIONES || "NINGUNA"
        }}</span>
      </h5>
      <h5 class="colorTextBlack">COMPROBANTES:</h5>
      <p-galleria
        [(value)]="images"
        [responsiveOptions]="responsiveOptions"
        [containerStyle]="{ 'max-width': '100%' }"
        [numVisible]="5"
      >
        <ng-template pTemplate="item" let-item>
          <img [src]="item" style="width: 90vw; display: block" />
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
          <div class="p-grid p-nogutter p-justify-center">
            <img [src]="item" style="height: 10vh; margin-right: 10px"/>
          </div>
        </ng-template>
      </p-galleria>
    </div>
  </div>

  <div class="p-fluid p-formgrid p-grid">
    <div class="p-col-12 p-mt-2">
      <div class="p-col-12">
        <h3>Agregar imagenes</h3>
      </div>
      <p-fileUpload
        multiple="multiple"
        accept="image/*"
        maxFileSize="1000000"
        customUpload="true"
        (uploadHandler)="onUpload($event)"
        [auto]="true"
        (onRemove)="onRemoveUpload($event)"
      >
      </p-fileUpload>
      <div class="p-col-12 p-lg-2">
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-plus"
          label="Cargar imagenes"
          (click)="cargarImagenes()"
          class="p-button-primary p-button-sm p-mt-2"
        ></button>
      </div>
    </div>
  </div>
</p-card>
