import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Cliente } from '../../models/cliente';
import { ControllerServicioService } from '../../servicios/controller-servicio.service';

@Component({
  selector: 'app-infocliente-model',
  templateUrl: './infocliente-model.component.html',
  styleUrls: ['./infocliente-model.component.scss'],

})
export class InfoclienteModelComponent implements OnInit {
  currentCliente: Cliente;
  saldoB = true;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public ctrlService: ControllerServicioService
  ) {
    this.currentCliente = ctrlService.getSelectedCliente();

    if (this.currentCliente.SALDO > 0) {
      this.saldoB = true;
    } else {
      this.saldoB = false;
    }
  }

  ngOnInit(): void {}

  closeDialog(save: number) {
    this.ref.close(save);
  }
}
