<p-toast>
  <ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column" style="flex: 1">
      <div class="p-text-center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
        <h4>{{ message.summary }}</h4>
        <p>{{ message.detail }}</p>
      </div>
    </div>
  </ng-template>
</p-toast>
<div *ngIf="!logued" class="p-fluid p-formgrid p-grid">
  <p-card class="p-col-12 p-lg-4 p-lg-offset-4 blueBack">
    <div class="p-grid">
      <div class="p-col-12">
        <h1>LOGIN</h1>
      </div>
      <div class="p-col-12">
        <div class="p-field">
          <label for="user">Usuario</label>
          <input
            id="user"
            type="text"
            [(ngModel)]="user"
            class="p-inputtext-sm"
            pInputText
          />
        </div>
      </div>
      <div class="p-col-12">
        <div class="p-field">
          <label for="password">Contraseña</label>
          <p-password
            id="password"
            [(ngModel)]="pass"
            feedback="false"
            toggleMask="true"
          ></p-password>
        </div>
      </div>
      <div class="p-col-12">
        <button
          pButton
          type="button"
          label="Login"
          class="p-button-sm p-button-success"
          (click)="checkLogin()"
        ></button>
      </div>
    </div>
  </p-card>
</div>
<div *ngIf="logued">
  <div class="p-grid">
    <div class="p-col">
      <p-menubar [model]="items"></p-menubar>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
