import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ControllerServicioService } from '../../servicios/controller-servicio.service';

@Component({
  selector: 'app-add-moneda',
  templateUrl: './add-moneda.component.html',
  styleUrls: ['./add-moneda.component.scss'],
})
export class AddMonedaComponent implements OnInit {
  formAddGroup: FormGroup;
  blocked: boolean = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formAdd: FormBuilder,
    public ctrlService: ControllerServicioService
  ) {
    this.formAddGroup = this.formAdd.group({
      NOMBRE: ['', Validators.required],
      FACCOMPRA: [1, Validators.required],
      FACVENTA: [1, Validators.required],
      OPERADORCOMPRA: [false, Validators.required],
      OPERADORVENTA: [false, Validators.required],
    });
  }

  ngOnInit(): void {}

  agregarMoneda() {
    this.blocked = true;
    this.ctrlService.addMoneda(this.formAddGroup.value).subscribe((res) => {
      this.ref.close(true);
    });
  }

  closeDialog(save: boolean) {
    this.ref.close(save);
  }
}
