import { Injectable } from '@angular/core';
import { Cliente } from '../models/cliente';
import { Moneda } from '../models/moneda';
import { MonedaxCLiente } from '../models/monedaxCliente';
import { Movimiento } from '../models/movimiento';
import { TestBoleta } from '../models/testBoleta';
import { Transaccion } from '../models/transaccion';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ControllerServicioService {
  //LOCAL
  //PHP_API_SERVER = 'http://localhost/TranServer/';

  //PHP_API_SERVER = 'https://transacapp.000webhostapp.com/TranServer/';

  //PRUEBAS
  //PHP_API_SERVER:string = 'http://transacest.42web.io/TranServer/';

  //CINTO
  PHP_API_SERVER: string = 'https://citransapp2023.ml/TranServer/';

  constructor(private http: HttpClient) {}

  //CLIENTE DATABASE
  allClientes(): Observable<Cliente[]> {
    var res = this.http.get<Cliente[]>(`${this.PHP_API_SERVER}cliente.php`);
    return res;
  }

  getCliente(id: number): Observable<Cliente> {
    return this.http.get<Cliente>(`${this.PHP_API_SERVER}cliente.php?ID=${id}`);
  }

  crearCliente(cliente: Cliente): Observable<Cliente> {
    var res = this.http.post<Cliente>(
      `${this.PHP_API_SERVER}cliente.php`,
      cliente
    );
    return res;
  }

  actualizarCLiente(cliente: Cliente): Observable<Cliente> {
    var res = this.http.post<Cliente>(
      `${this.PHP_API_SERVER}cliente.php`,
      cliente
    );
    return res;
  }

  setSelectedCliente(cliente: Cliente) {
    localStorage.setItem('CLIENTESELECTED', JSON.stringify(cliente));
  }

  getSelectedCliente(): Cliente {
    return (
      JSON.parse(localStorage.getItem('CLIENTESELECTED') || '') || new Cliente()
    );
  }

  updateSaldoCliente(id: number, cantidad: number) {
    var cliente = new Cliente();
    this.getCliente(id).subscribe((res) => {
      cliente = res;
      cliente.SALDO = cliente.SALDO + cantidad;
      this.actualizarCLiente(cliente).subscribe();
    });
  }

  //BOLETAS
  addTestBoletas(boleta: TestBoleta) {
    this.http
      .post<boolean>(`${this.PHP_API_SERVER}boleta.php`, boleta)
      .subscribe((res) => {});
  }

  getTestBoletaByID(boleta: string): Observable<TestBoleta> {
    return this.http.get<TestBoleta>(
      `${this.PHP_API_SERVER}boleta.php?BOLETA=${boleta}`
    );
  }

  getTestBoletaByTrans(trans: number): Observable<TestBoleta[]> {
    return this.http.get<TestBoleta[]>(
      `${this.PHP_API_SERVER}boleta.php?TRANS=${trans}`
    );
  }

  getTestBoletas(): Observable<TestBoleta[]> {
    return this.http.get<TestBoleta[]>(`${this.PHP_API_SERVER}boleta.php`);
  }

  checkLogin(nick: string, contra: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.PHP_API_SERVER}login.php?NICK=${nick}&CONTRA=${contra}`
    );
  }

  //MONEDA
  setSelectedMoneda(moneda: Moneda) {
    localStorage.setItem('SELECTEDMONEDA', JSON.stringify(moneda));
  }

  getSelectedMoneda() {
    return JSON.parse(localStorage.getItem('SELECTEDMONEDA') || '');
  }

  getMonedas(): Observable<Moneda[]> {
    return this.http.get<Moneda[]>(`${this.PHP_API_SERVER}moneda.php`);
  }

  addMoneda(moneda: Moneda) {
    return this.http.post<boolean>(`${this.PHP_API_SERVER}moneda.php`, moneda);
  }

  getMoneda(nombre: string): Observable<Moneda> {
    return this.http.get<Moneda>(
      `${this.PHP_API_SERVER}moneda.php?ID=${nombre}`
    );
  }

  editMoneda(moneda: Moneda) {
    return this.http.post<boolean>(`${this.PHP_API_SERVER}moneda.php`, moneda);
  }

  //MONEDA X CLIENTE

  getMonedasxClienteByID(id: number): Observable<MonedaxCLiente[]> {
    return this.http.get<MonedaxCLiente[]>(
      `${this.PHP_API_SERVER}clientexmoneda.php?ID=${id}`
    );
  }

  addMonedaxCLiente(moneda: MonedaxCLiente): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.PHP_API_SERVER}clientexmoneda.php`,
      moneda
    );
  }

  buscarMonedaxClienteByIDNombre(
    nombre: string,
    id: number
  ): Observable<MonedaxCLiente> {
    return this.http.get<MonedaxCLiente>(
      `${this.PHP_API_SERVER}clientexmoneda.php?ID=${id}&MONEDA=${nombre}`
    );
  }

  updateMonedaxClienteTrans(trans: Transaccion) {
    this.buscarMonedaxClienteByIDNombre(trans.MONEDA, trans.DE).subscribe(
      (res) => {
        var monedaDe = res || new MonedaxCLiente();
        if (monedaDe.NOMBRE !== '') {
          monedaDe.FACCOMPRA = trans.FCOM;
          monedaDe.OPERADORCOMPRA = trans.FCOMT;
          this.addMonedaxCLiente(monedaDe).subscribe();
        } else {
          this.getMoneda(trans.MONEDA).subscribe((res) => {
            var monedaTemp = res;
            monedaDe.CLIENTEID = trans.DE;
            monedaDe.FACVENTA = monedaTemp.FACVENTA;
            monedaDe.NOMBRE = monedaTemp.NOMBRE;
            monedaDe.OPERADORVENTA = monedaTemp.OPERADORVENTA;
            monedaDe.FACCOMPRA = trans.FCOM;
            monedaDe.OPERADORCOMPRA = trans.FCOMT;
            this.addMonedaxCLiente(monedaDe).subscribe();
          });
        }
      }
    );

    this.buscarMonedaxClienteByIDNombre(trans.MONEDA, trans.PARA).subscribe(
      (res) => {
        var monedaPara = res || new MonedaxCLiente();
        if (monedaPara.NOMBRE !== '') {
          monedaPara.FACVENTA = trans.FVEN;
          monedaPara.OPERADORVENTA = trans.FVENT;
          this.addMonedaxCLiente(monedaPara).subscribe();
        } else {
          this.getMoneda(trans.MONEDA).subscribe((res) => {
            var monedaTemp = res;
            monedaPara.CLIENTEID = trans.PARA;
            monedaPara.FACCOMPRA = monedaTemp.FACCOMPRA;
            monedaPara.NOMBRE = monedaTemp.NOMBRE;
            monedaPara.OPERADORCOMPRA = monedaTemp.OPERADORCOMPRA;
            monedaPara.FACVENTA = trans.FVEN;
            monedaPara.OPERADORVENTA = trans.FVENT;
            this.addMonedaxCLiente(monedaPara).subscribe();
          });
        }
      }
    );
  }

  //TRANSACCIONES

  getTransaccionSelected(): Transaccion {
    return JSON.parse(localStorage.getItem('TRANSACCIONSELECTED') || '[]');
  }

  setTransaccionSelected(trans: Transaccion) {
    localStorage.setItem('TRANSACCIONSELECTED', JSON.stringify(trans));
  }

  addMovimientos(mov: Movimiento) {
    this.http.post(`${this.PHP_API_SERVER}movimiento.php`, mov).subscribe();
  }

  actualizarComprobante(trans: Transaccion) {
    this.http.post(`${this.PHP_API_SERVER}transaccion.php`, trans).subscribe();
  }

  actualizarTransaccion(trans: Transaccion): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.PHP_API_SERVER}transaccion.php`,
      trans
    );
  }

  eliminarTransaccion(trans: Transaccion): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.PHP_API_SERVER}deleteTransaccion.php`,
      trans
    );
  }

  getMovimientosByCliente(
    id: number,
    tipo: number,
    start: string,
    end: string,
    estado: string
  ): Observable<Movimiento[]> {
    return this.http.get<Movimiento[]>(
      `${this.PHP_API_SERVER}movimiento.php?ID=${id}&TIPO=${tipo}&START=${start}&END=${end}&ESTADO=${estado}`
    );
  }

  getTransaccionByID(id: number): Observable<Transaccion> {
    return this.http.get<Transaccion>(
      `${this.PHP_API_SERVER}transaccion.php?ID=${id}`
    );
  }

  crearTransacciones(transaccion: Transaccion): Observable<number> {
    return this.http.post<number>(
      `${this.PHP_API_SERVER}transaccion.php`,
      transaccion
    );
  }

  getTransacciones(
    start: string,
    end: string,
    tipo: string
  ): Observable<Transaccion[]> {
    return this.http.get<Transaccion[]>(
      `${this.PHP_API_SERVER}transaccion.php?START=${start}&END=${end}&TIPO=${tipo}`
    );
  }

  cargarImages(images: any[], folder: string, index: number): string {
    var i: number = index;
    var result: string = '';
    images.forEach((element) => {
      result = result + ',bol-' + i + '.jpg';
      this.uploadFile(element, 'bol-' + i + '.jpg', folder).subscribe(
        (res) => {}
      );
      i++;
    });
    return result;
  }

  uploadFile(file: any, name: string, folder: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('image', file, name);
    //append any other key here if required
    return this.http.post(
      `${this.PHP_API_SERVER}upload.php?folder=${folder}`,
      formData
    );
  }
}
