import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Saldo } from '../models/saldo';
import { Transaccion } from '../models/transaccion';

@Injectable({
  providedIn: 'root',
})
export class DiarioService {

  //PHP_API_SERVER = 'http://localhost/TranServer/';
  //PHP_API_SERVER = 'https://transacapp.000webhostapp.com/TranServer/';

  //PRUEBAS
  //PHP_API_SERVER:string = 'http://transacest.42web.io/TranServer/';

  //CINTO
  PHP_API_SERVER:string = 'https://citransapp2023.ml/TranServer/';

  constructor(private http: HttpClient) {

  }


  getSaldos():Observable<Saldo>{
    return this.http.get<Saldo>(`${this.PHP_API_SERVER}diario.php?TIPO=SALDOS`);
  }

  getGastoDiario(start:string, end:string):Observable<number>{
    return this.http.get<number>(`${this.PHP_API_SERVER}diario.php?TIPO=SALDOG&START=${start}&END=${end}`);
  }

  getLocalDiario(start:string, end:string):Observable<number>{
    return this.http.get<number>(`${this.PHP_API_SERVER}diario.php?TIPO=SALDOL&START=${start}&END=${end}`);
  }

  getExtraDiario(start:string, end:string):Observable<number>{
    return this.http.get<number>(`${this.PHP_API_SERVER}diario.php?TIPO=SALDOE&START=${start}&END=${end}`);
  }

  getExtranjerasDia(start:string, end:string):Observable<Transaccion[]>{
    return this.http.get<Transaccion[]>(`${this.PHP_API_SERVER}diario.php?TIPO=EXTRA&START=${start}&END=${end}`);
  }

  getLocalesDia(start:string, end:string):Observable<Transaccion[]>{
    return this.http.get<Transaccion[]>(`${this.PHP_API_SERVER}diario.php?TIPO=LOCAL&START=${start}&END=${end}`);
  }

  getGastosDia(start:string, end:string):Observable<Transaccion[]>{
    return this.http.get<Transaccion[]>(`${this.PHP_API_SERVER}diario.php?TIPO=GASTO&START=${start}&END=${end}`);
  }

}
