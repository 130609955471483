<form [formGroup]="formAddGroup">
  <div class="p-fluid  p-grid">
    <div class="p-col-12">
      <div class="p-field">
        <p-dropdown
          [options]="monedas"
          optionGroupLabel="MONEDA"
          optionLabel="NOMBRE"
          (onChange)="updateInfo()"
          emptyMessage="Seleccione uno"
          formControlName="MONEDA"
        ></p-dropdown>
      </div>
    </div>
    <div class="p-field p-lg-6 p-md-6 p-sm-6">
      <label for="faccompra">Factor de Compra</label>
      <p-inputNumber
        id="faccompra"
        mode="decimal"
        [useGrouping]="false"
        class="p-inputtext-sm"
        formControlName="FACCOMPRA"
        locale="en-US"
        [minFractionDigits]="2"
        [maxFractionDigits]="10"
      ></p-inputNumber>
    </div>
    <div class="p-field p-lg-6 p-md-6 p-sm-6 p-mt-lg-5">
      <p-checkbox
        [binary]="true"
        label="Por Division"
        formControlName="OPERADORCOMPRA"
      ></p-checkbox>
    </div>
    <div class="p-field p-lg-6 p-md-6 p-sm-6">
      <label for="facventa">Factor de Venta</label>
      <p-inputNumber
        id="facventa"
        mode="decimal"
        [useGrouping]="false"
        class="p-inputtext-sm"
        formControlName="FACVENTA"
        locale="en-US"
        [minFractionDigits]="2"
        [maxFractionDigits]="10"
      ></p-inputNumber>
    </div>
    <div class="p-field p-lg-6 p-md-6 p-sm-6 p-mt-lg-5">
      <p-checkbox
        [binary]="true"
        label="Por Division"
        formControlName="OPERADORVENTA"
      ></p-checkbox>
    </div>
    <div class="p-field p-col-12">
      <span class="p-buttonset">
        <button
          pButton
          type="button"
          label="Agregar"
          icon="pi pi-check"
          class="p-button-sm p-button-primary"
          [disabled]="!formAddGroup.valid"
          (click)="addMonedaxCliente()"
        ></button>
        <button
          pButton
          type="button"
          label="Cancel"
          icon="pi pi-times"
          class="p-button-sm p-button-danger p-ml-2"
          (click)="closeDialog(false)"
        ></button>
      </span>
    </div>
  </div>
</form>
<div class="p-col-12 p-grid">
  <div class="p-col-12">
    <h2>Monedas del cliente</h2>
  </div>
  <div class="p-col-12">
    <p-table [value]="monedaxCliente" styleClass="p-datatable-responsive-demo" [loading]="blocked">
      <ng-template pTemplate="header">
        <tr>
          <th>Nombre</th>
          <th>Operador Compra</th>
          <th>Factor Compra</th>
          <th>Operador Venta</th>
          <th>Factor Venta</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-cli>
        <tr>
          <td>{{ cli.NOMBRE }}</td>
          <td>{{ cli.OPERADORCOMPRA ? "DIVISION" : "MULTIPLICACION" }}</td>
          <td>{{ cli.FACCOMPRA }}</td>
          <td>{{ cli.OPERADORVENTA ? "DIVISION" : "MULTIPLICACION" }}</td>
          <td>{{ cli.FACVENTA }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
