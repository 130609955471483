<p-blockUI [blocked]="blocked" class="fixMask">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
<p-toast>
  <ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column" style="flex: 1">
      <div class="p-text-center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
        <h4>{{ message.summary }}</h4>
        <p>{{ message.detail }}</p>
        <button
          pButton
          pRipple
          type="button"
          label="Ver Transaccion"
          class="p-button-primary p-button-sm"
          *ngIf="message.data.idtrans"
          (click)="verTransaccion()"
        ></button>
      </div>
    </div>
  </ng-template>
</p-toast>
<div class="p-fluid p-formgrid p-grid">
  <div class="p-col-12 p-grid" *ngIf="trans.TIPO == 0">
    <div class="p-col-12">
      <p-divider></p-divider>
    </div>
    <div class="p-lg-3 p-col-12">
      <label for="MONEDA">Moneda</label>
      <p-dropdown
        id="MONEDA"
        [options]="monedasG"
        optionGroupLabel="MONEDA"
        optionLabel="NOMBRE"
        (onChange)="updateInfo()"
        [(ngModel)]="monedaSelected"
      ></p-dropdown>
    </div>
    <div class="p-col-12 p-lg-3 p-mt-lg-1 p-mt-3">
      <label for="CANTIDAD">Cantidad:</label>
      <p-inputNumber
        class="p-inputtext-sm"
        inputId="CANTIDAD"
        mode="decimal"
        locale="en-US"
        [minFractionDigits]="2"
        [maxFractionDigits]="2"
        [(ngModel)]="trans.CMONEDA"
      ></p-inputNumber>
    </div>
    <div class="p-col-12 p-lg-4 p-mt-lg-5 p-mt-3">
      <p-checkbox
        label="Factores por defecto"
        [binary]="true"
        [(ngModel)]="monedaDefecto"
        (click)="setFactores()"
      ></p-checkbox>
    </div>
    <div class="p-col-12 p-lg-3 p-mt-3 p-mt-lg-2">
      <label for="FACCOMPRA">Factor de Compra</label>
      <p-inputNumber
        class="p-inputtext-sm"
        inputId="FACCOMPRA"
        mode="decimal"
        locale="en-US"
        [minFractionDigits]="2"
        [maxFractionDigits]="10"
        [(ngModel)]="trans.FCOM"
      ></p-inputNumber>
    </div>
    <div class="p-col-12 p-lg-2 p-mt-lg-5 p-mt-2">
      <p-checkbox
        label="Por Division"
        [binary]="true"
        [(ngModel)]="trans.FCOMT"
      ></p-checkbox>
    </div>
    <div class="p-col-12 p-lg-5 p-mt-lg-2">
      <h3 id="QCOM">
        Q
        {{
          (trans.FCOMT
            ? trans.CMONEDA / trans.FCOM
            : trans.CMONEDA * trans.FCOM
          ) | number: "1.2-2"
        }}
      </h3>
    </div>

    <div class="p-col-12 p-lg-3 p-mt-lg-2">
      <label for="FVEN">Factor de Venta</label>
      <p-inputNumber
        class="p-inputtext-sm"
        inputId="FVEN"
        mode="decimal"
        locale="en-US"
        [minFractionDigits]="2"
        [maxFractionDigits]="10"
        [(ngModel)]="trans.FVEN"
      ></p-inputNumber>
    </div>
    <div class="p-col-12 p-lg-2 p-mt-lg-5 p-mt-2">
      <p-checkbox
        label="Por Division"
        [(ngModel)]="trans.FVENT"
        [binary]="true"
      ></p-checkbox>
    </div>
    <div class="p-col-12 p-lg-5 p-mt-lg-2">
      <h3 id="QVEN">
        Q
        {{
          (trans.FVENT
            ? trans.CMONEDA / trans.FVEN
            : trans.CMONEDA * trans.FVEN
          ) | number: "1.2-2"
        }}
      </h3>
    </div>
  </div>
  <div class="p-col-12 p-grid" *ngIf="trans.TIPO == 1">
    <div class="p-col-12">
      <p-divider></p-divider>
    </div>
    <div class="p-lg-3 p-col-12">
      <label for="QTRAN">Cantidad:</label>
      <p-inputNumber
        class="p-inputtext-sm"
        inputId="QTRAN"
        mode="decimal"
        locale="en-US"
        [minFractionDigits]="2"
        [maxFractionDigits]="2"
        [(ngModel)]="trans.QTRAN"
      ></p-inputNumber>
    </div>
  </div>
  <div class="p-col-12" *ngIf="trans.TIPO == 2">
    <div class="p-col-12">
      <p-divider></p-divider>
    </div>
    <div class="p-col-12 p-grid p-mt-2">
      <div class="p-col-12">
        <label for="DESCRIPCION">Descripción:</label>
        <textarea
          id="DESCRIPCION"
          type="text"
          class="p-inputtext-sm"
          pInputText
          [(ngModel)]="trans.DESCRIPCION"
          pInputTextarea
          [rows]="5"
        ></textarea>
      </div>
    </div>
    <div class="p-lg-3 p-col-12">
      <label for="QTRAN">Cantidad:</label>
      <p-inputNumber
        class="p-inputtext-sm"
        inputId="QTRAN"
        mode="decimal"
        locale="en-US"
        [minFractionDigits]="2"
        [maxFractionDigits]="2"
        [(ngModel)]="trans.QTRAN"
      ></p-inputNumber>
    </div>
  </div>
  <div class="p-col-12">
    <p-divider></p-divider>
  </div>
  <div class="p-col-12 p-grid">
    <div class="p-col-10 p-lg-3">
      <label for="BOLETAS">No. Boleta</label>
      <input
        id="BOLETAS"
        type="text"
        class="p-inputtext-sm"
        pInputText
        [(ngModel)]="ticketAdd"
      />
    </div>
    <div class="p-mt-4 p-col-2">
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-plus"
        class="p-button-primary p-button-sm"
        (click)="addAndCheck()"
      ></button>
    </div>
  </div>
  <div class="p-col-12 p-grid p-mt-2">
    <div *ngFor="let bol of trans.BOLETAS; let i = index">
      <div class="p-col-12 p-mt-1">
        <p-chip
          [label]="bol"
          icon="pi pi-ticket"
          [removable]="true"
          (onRemove)="removeBoleto(i)"
        ></p-chip>
      </div>
    </div>
  </div>

  <div class="p-col-12 p-grid p-mt-1">
    <div class="p-col-12 p-lg-3">
      <label for="BANCO">Banco:</label>
      <input
        id="BANCO"
        type="text"
        class="p-inputtext-sm"
        pInputText
        [(ngModel)]="trans.BANCO"
      />
    </div>
  </div>
  <div class="p-col-12 p-grid p-mt-2">
    <div class="p-col-12 p-lg-3">
      <label for="DESTINATARIO">Destinatario:</label>
      <input
        id="DESTINATARIO"
        type="text"
        class="p-inputtext-sm"
        pInputText
        [(ngModel)]="trans.DESTINATARIO"
      />
    </div>
  </div>
  <div class="p-col-12 p-grid p-mt-2">
    <div class="p-col-12">
      <label for="OBSERVACIONES">Observaciones:</label>
      <textarea
        id="DESTINATARIO"
        type="text"
        class="p-inputtext-sm"
        pInputText
        [(ngModel)]="trans.OBSERVACIONES"
        pInputTextarea
        [rows]="5"
      ></textarea>
    </div>
  </div>
  <div class="p-field p-col-12">
    <span class="p-buttonset">
      <button
        pButton
        type="button"
        label="Guardar"
        icon="pi pi-check"
        class="p-button-sm p-button-primary"
        (click)="actualizarTransaccion()"
      ></button>
      <button
        pButton
        type="button"
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-sm p-button-danger p-ml-2"
        (click)="closeDialog(false)"
      ></button>
    </span>
  </div>
</div>
