import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { AddClienteComponent } from '../add-cliente/add-cliente.component';
import { InfoclienteModelComponent } from '../infocliente-model/infocliente-model.component';
import { Cliente } from '../../models/cliente';
import { ControllerServicioService } from '../../servicios/controller-servicio.service';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss'],
  providers: [DialogService],
})
export class ClientesComponent implements OnInit {
  clientes: Cliente[] = [];
  blocked: boolean = true;

  constructor(
    public dialogService: DialogService,
    private Ruta: Router,
    public ctrlService: ControllerServicioService
  ) {
    this.cargarClientes();
  }

  cargarClientes() {
    this.ctrlService.allClientes().subscribe(
      (res) => {
        this.clientes = res;
        this.blocked = false;
      },
      (err) => {
        this.cargarClientes();
      }
    );
  }

  ngOnInit(): void {}

  agregarCliente() {
    const ref = this.dialogService.open(AddClienteComponent, {
      data: {},
      header: 'Cliente Nuevo',
      width: '100vw',
    });

    ref.onClose.subscribe((res) => {
      if (res) {
        this.openModalInfoCliente();
      } else {
        this.updateClientes();
      }
    });
  }

  openModalInfoCliente() {
    const ref = this.dialogService.open(InfoclienteModelComponent, {
      data: {},
      header: 'Cliente creado con éxito',
      width: '100vw',
    });

    ref.onClose.subscribe((res) => {
      if (res == 0) {
        this.Ruta.navigateByUrl('/Cliente');
      } else if (res == 1) {
        this.agregarCliente();
      } else {
        this.updateClientes();
      }
    });
  }

  updateClientes() {
    this.blocked = true;
    this.ctrlService.allClientes().subscribe((res) => {
      this.clientes = res;
      this.blocked = false;
    });
  }

  resendCliente(cliente: Cliente) {
    this.ctrlService.setSelectedCliente(cliente);
    this.Ruta.navigateByUrl('/Cliente');
  }
}
