import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { Saldo } from 'src/app/models/saldo';
import { Transaccion } from 'src/app/models/transaccion';
import { ControllerServicioService } from 'src/app/servicios/controller-servicio.service';
import { DiarioService } from 'src/app/servicios/diario.service';

@Component({
  selector: 'app-diario',
  templateUrl: './diario.component.html',
  styleUrls: ['./diario.component.scss'],
})
export class DiarioComponent implements OnInit {
  saldos: Saldo = new Saldo();

  extraDiario: number = 0;
  gastoDiario: number = 0;
  localDiario: number = 0;

  lsextraDiario: Transaccion[] = [];
  lsgastoDiario: Transaccion[] = [];
  lslocalDiario: Transaccion[] = [];

  blocked: boolean = false;

  startDate: Date = new Date();

  endDate: Date = new Date();

  cantidadesPorMonedaExtranjero = new Map<string, number>();
  cantidadesPorMonedaExtranjeroArray = Array.from(
    this.cantidadesPorMonedaExtranjero,
    ([moneda, cantidad]) => ({ moneda, cantidad })
  );

  constructor(
    private Ruta: Router,
    private ctrlService: DiarioService,
    private ctrlServiceGeneral: ControllerServicioService
  ) {
    this.loadData(new Date(), new Date());
  }

  ngOnInit(): void {}

  calcularTotalExtranjero() {
    this.cantidadesPorMonedaExtranjero = new Map<string, number>();
    for (const tran of this.lsextraDiario) {
      const cantidadAcumulada =
        this.cantidadesPorMonedaExtranjero.get(tran.MONEDA) ?? 0;
      this.cantidadesPorMonedaExtranjero.set(
        tran.MONEDA,
        cantidadAcumulada + tran.CMONEDA
      );
    }
    this.cantidadesPorMonedaExtranjeroArray = Array.from(
      this.cantidadesPorMonedaExtranjero,
      ([moneda, cantidad]) => ({ moneda, cantidad })
    );
  }

  filtrar() {
    this.loadData(this.startDate, this.endDate);
  }

  reiniciar() {
    this.startDate = new Date();
    this.endDate = new Date();
    this.loadData(this.startDate, this.endDate);
  }

  loadData(start: Date, end: Date) {
    //gasto cliente
    this.blocked = true;
    let getSaldos = this.ctrlService.getSaldos();

    //gasto por tipo
    let getGastoDiario = this.ctrlService.getGastoDiario(
      this.convertDateSQL(start),
      this.convertDateSQL(end)
    );
    let getLocalDiario = this.ctrlService.getLocalDiario(
      this.convertDateSQL(start),
      this.convertDateSQL(end)
    );
    let getExtraDiario = this.ctrlService.getExtraDiario(
      this.convertDateSQL(start),
      this.convertDateSQL(end)
    );

    //arreglos
    let getExtranjerasDia = this.ctrlService.getExtranjerasDia(
      this.convertDateSQL(start),
      this.convertDateSQL(end)
    );
    let getLocalesDia = this.ctrlService.getLocalesDia(
      this.convertDateSQL(start),
      this.convertDateSQL(end)
    );
    let getGastosDia = this.ctrlService.getGastosDia(
      this.convertDateSQL(start),
      this.convertDateSQL(end)
    );

    forkJoin({
      getSaldos,
      getGastoDiario,
      getLocalDiario,
      getExtraDiario,
      getExtranjerasDia,
      getLocalesDia,
      getGastosDia,
    }).subscribe(
      (restults) => {
        this.saldos = restults.getSaldos;

        this.extraDiario = restults.getExtraDiario;
        this.gastoDiario = restults.getGastoDiario;
        this.localDiario = restults.getLocalDiario;

        this.lsextraDiario = restults.getExtranjerasDia;
        this.lsgastoDiario = restults.getGastosDia;
        this.lslocalDiario = restults.getLocalesDia;

        this.calcularTotalExtranjero();
        this.blocked = false;
      },
      (err) => {
        this.loadData(this.startDate, this.endDate);
      }
    );
  }

  convertDateSQL(date: Date): string {
    return moment(date).format('yyyy-MM-DD');
  }

  selectTransaccion(trans: Transaccion) {
    this.ctrlServiceGeneral.setTransaccionSelected(trans);
    this.Ruta.navigateByUrl('/Transaccion');
  }
}
