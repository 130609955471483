<p-blockUI [blocked]="blocked" class="fixMask">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
<form [formGroup]="formAddGroup">
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-col-12">
      <div class="p-field">
        <label for="nombre">Nombre</label>
        <input
          id="nombre"
          type="text"
          class="p-inputtext-sm"
          pInputText
          formControlName="NOMBRE"
        />
      </div>
    </div>
    <div class="p-field p-lg-6 p-md-12 p-sm-12">
      <label for="telefono">Telefono</label>
      <input
        id="telefono"
        type="text"
        formControlName="TELEFONO"
        class="p-inputtext-sm"
        pInputText
      />
    </div>
    <div class="p-field p-lg-6 p-md-12 p-sm-12">
      <label for="saldo">Saldo inicial</label>
      <p-inputNumber
        id="saldo"
        mode="decimal"
        locale="en-US"
        [minFractionDigits]="2"
        [maxFractionDigits]="2"
        class="p-inputtext-sm"
        formControlName="SALDO"
      ></p-inputNumber>
    </div>
    <div class="p-field p-lg-6 p-md-12 p-sm-12">
      <label for="posneg">Saldo inicial</label>
      <p-selectButton
        id="posneg"
        [options]="stateOptions"
        formControlName="POSNEG"
        optionLabel="label"
      ></p-selectButton>
    </div>
    <div class="p-field p-col-12">
      <span class="p-buttonset">
        <button
          pButton
          type="button"
          label="Guardar"
          icon="pi pi-check"
          class="p-button-sm p-button-primary"
          (click)="guardarCliente()"
        ></button>
        <button
          pButton
          type="button"
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-sm p-button-danger p-ml-2"
          (click)="closeDialog(false)"
        ></button>
      </span>
    </div>
  </div>
</form>
