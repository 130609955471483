import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Moneda } from '../../models/moneda';
import { ControllerServicioService } from '../../servicios/controller-servicio.service';

@Component({
  selector: 'app-edit-moneda',
  templateUrl: './edit-moneda.component.html',
  styleUrls: ['./edit-moneda.component.scss'],
})
export class EditMonedaComponent implements OnInit {
  formAddGroup: FormGroup;
  selectedMoneda: Moneda;
  blocked: boolean = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formAdd: FormBuilder,
    public ctrlService: ControllerServicioService
  ) {
    this.selectedMoneda = ctrlService.getSelectedMoneda();
    this.formAddGroup = this.formAdd.group({
      NOMBRE: [this.selectedMoneda.NOMBRE, Validators.required],
      FACCOMPRA: [this.selectedMoneda.FACCOMPRA, Validators.required],
      FACVENTA: [this.selectedMoneda.FACVENTA, Validators.required],
      OPERADORCOMPRA: [this.selectedMoneda.OPERADORCOMPRA, Validators.required],
      OPERADORVENTA: [this.selectedMoneda.OPERADORVENTA, Validators.required],
    });
  }

  ngOnInit(): void {}

  updateMoneda() {
    this.blocked = true;
    this.ctrlService.editMoneda(this.formAddGroup.value).subscribe((res) => {
      this.ref.close(true);
    });
  }

  closeDialog(save: boolean) {
    this.ref.close(save);
  }
}
