import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MenuModule } from 'primeng/menu';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenubarModule } from 'primeng/menubar';
import { AddClienteComponent } from './cliente/add-cliente/add-cliente.component';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { ClientesComponent } from './cliente/clientes/clientes.component';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { TableModule } from 'primeng/table';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { InfoclienteModelComponent } from './cliente/infocliente-model/infocliente-model.component';
import { ViewClienteComponent } from './cliente/view-cliente/view-cliente.component';
import { TabViewModule } from 'primeng/tabview';
import { EditClienteComponent } from './cliente/edit-cliente/edit-cliente.component';
import { TransaccionesComponent } from './transaccion/transacciones/transacciones.component';
import { TransacProcesComponent } from './transaccion/transac-proces/transac-proces.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { FieldsetModule } from 'primeng/fieldset';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ChipModule } from 'primeng/chip';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MonedaManagerComponent } from './moneda/moneda-manager/moneda-manager.component';
import { AddMonedaComponent } from './moneda/add-moneda/add-moneda.component';
import { EditMonedaComponent } from './moneda/edit-moneda/edit-moneda.component';
import { MonedaClienteComponent } from './cliente/moneda-cliente/moneda-cliente.component';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { TransaccionComponent } from './transaccion/transaccion/transaccion.component';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { DiarioComponent } from './consulta/diario/diario.component';
import { LoginComponent } from './login/login/login.component';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {BlockUIModule} from 'primeng/blockui';
import {CalendarModule} from 'primeng/calendar';
import { EditTransacccionComponent } from './transaccion/transaccion/edit-transacccion/edit-transacccion.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';



@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    AddClienteComponent,
    ClientesComponent,
    InfoclienteModelComponent,
    ViewClienteComponent,
    EditClienteComponent,
    TransaccionesComponent,
    TransacProcesComponent,
    MonedaManagerComponent,
    AddMonedaComponent,
    EditMonedaComponent,
    MonedaClienteComponent,
    TransaccionComponent,
    DiarioComponent,
    LoginComponent,
    EditTransacccionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MenuModule,
    MegaMenuModule,
    MenubarModule,
    InputTextModule,
    CardModule,
    ButtonModule,
    DividerModule,
    TableModule,
    DynamicDialogModule,
    BrowserAnimationsModule,
    InputNumberModule,
    CheckboxModule,
    TabViewModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SelectButtonModule,
    ToggleButtonModule,
    FieldsetModule,
    AutoCompleteModule,
    ChipModule,
    InputTextareaModule,
    CascadeSelectModule,
    DropdownModule,
    ToastModule,
    FileUploadModule,
    GalleriaModule,
    PasswordModule,
    ProgressSpinnerModule,
    BlockUIModule,
    CalendarModule,
    ConfirmDialogModule,
    
  ],
  entryComponents: [],
  providers: [
    AddClienteComponent,
    InfoclienteModelComponent,
    EditClienteComponent,
    AddMonedaComponent,
    EditMonedaComponent,
    MonedaClienteComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
