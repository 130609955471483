<p-blockUI [blocked]="blocked" class="fixMask">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
<p-card>
  <div class="p-grid">
    <div class="p-col-12">
      <h2>Monedas</h2>
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-plus"
        class="p-button-success p-button-sm"
        label="Agregar Moneda"
        (click)="agregarMoenda()"
      ></button>
    </div>
    <div class="p-col-12">
      <p-table
        [value]="monedas"
        styleClass="p-datatable-sm p-datatable-responsive-demo"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>MONEDA</th>
            <th>OPERADOR COMPRA</th>
            <th>FACTOR COMPRA</th>
            <th>OPERADOR VENTA</th>
            <th>FACTOR VENTA</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-cli>
          <tr class="manita" (click)="editarMoneda(cli)">
            <td class="blackText">{{ cli.NOMBRE }}</td>
            <td>
              <span class="shw">Operador Compra: </span
              >{{ cli.OPERADORCOMPRA ? "DIVISION" : "MULTIPLICACION" }}
            </td>
            <td><span class="shw">Factor Compra: </span>{{ cli.FACCOMPRA }}</td>
            <td>
              <span class="shw">Operador Venta: </span
              >{{ cli.OPERADORVENTA ? "DIVISION" : "MULTIPLICACION" }}
            </td>
            <td><span class="shw">Factor Venta: </span>{{ cli.FACVENTA }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-card>
