<p-blockUI [blocked]="blocked" class="fixMask">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
<p-card>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-col-12">
      <p-divider></p-divider>
      <div class="p-field p-col-12 p-md-2">
        <button
          pButton
          type="button"
          label="Agregar Cliente"
          (click)="agregarCliente()"
          icon="pi pi-plus"
          iconPos="right"
          class="p-button-success p-button-sm"
        ></button>
      </div>
      <p-divider></p-divider>
      <div class="p-col-12 p-grid">
        <div class="p-col-12">
          <h2>Clientes</h2>
        </div>
        <div class="p-col-12">
          <p-table
            [value]="clientes"
            dataKey="ID"
            [globalFilterFields]="['NOMBRE', 'TELEFONO']"
            styleClass="p-datatable-responsive-demo"
          >
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="ID">ID</th>
                <th pSortableColumn="NOMBRE">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    Nombre
                    <p-columnFilter
                      type="text"
                      field="NOMBRE"
                      display="menu"
                    ></p-columnFilter>
                  </div>
                </th>
                <th pSortableColumn="TELEFONO">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    Teléfono
                    <p-columnFilter
                      type="text"
                      field="TELEFONO"
                      display="menu"
                    ></p-columnFilter>
                  </div>
                </th>
                <th pSortableColumn="SALDO">Saldo</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-cli>
              <tr class="manita" (click)="resendCliente(cli)">
                <td class="blackText">{{ cli.ID }}</td>
                <td>{{ cli.NOMBRE }}</td>
                <td>{{ cli.TELEFONO }}</td>
                <td
                  [ngClass]="cli.SALDO > 0 ? 'colorTextGreen' : 'colorTextRed'"
                >
                  {{ cli.SALDO | currency: "Q" }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</p-card>
